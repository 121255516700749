import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem, TextBtnItem, TextBtnItemBox } from "component/basic/btns";
import { InputItemBox } from "component/basic/formItems";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, ItemSection, PageSizing } from "component/app/items";
import { Popup } from "component/basic/popup";
import { PhoneSetting } from "js/function";

const Edit = (props) => {
    const navigate = useNavigate();

    const [update,setUpdate] =  useState(0);

    const mypageApi = useGet({
        url:`/mypage/info?update=${update}`,
        loginType:"login"
    });
    const mypageData = mypageApi?.data?.user_info;

    const [nickName, setNickName] = useState("");

    const [popupData,setPopupData] =  useState(null);

    const [btnChk,setBtnChk] =  useState(true);
    const [submitChk,setSubmitChk] =  useState(true);

    function withdrawalOpen(){
        setPopupData({
            addClass:null,
            title:"회원 탈퇴",
            text:"회원 탈퇴 시 더 이상 오토노바 서비스를<br/>이용하실 수 없습니다.<br/><br/>정말 탈퇴하시겠습니까?",
            closeType:true,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{withdrawalSubmit()},
            btn0:"회원탈퇴",
            btn0Type:"",
            btn1:"취소",
            btn1Type:"",
            btnFunc1:()=>{}
        });
    }

    function withdrawalSubmit(){
        if(submitChk){
            setSubmitChk(false);
        
            let formData = new FormData();

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/withdrawal`,
                success: (data) => {
                    setSubmitChk(true);
                    setPopupData({
                        addClass:null,
                        title:"회원 탈퇴 완료",
                        text:"그동안 오토노바 서비스를 이용해 주셔서 감사합니다.",
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{
                            window.logOut();
                            navigate("/");
                        },
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                }
            })
        }    
    }

    function editMyData(){
        if(submitChk){
            setSubmitChk(false);
            let formData = new FormData();
            formData.append("nickname", nickName);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/mypage/info/modify`,
                success: (data) => {
                    setSubmitChk(true);
                    setPopupData({
                        addClass:null,
                        title:"내 정보 저장 완료",
                        text:"내 정보 저장이 완료되었습니다.",
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{
                            setUpdate(update+1);
                        },
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                }
            })
        }
    }

    useEffect(() => {
        if(mypageData){
            setNickName(mypageData?.nickname);
        }
    }, [mypageApi]);

    useEffect(() => {
        if(nickName !== ""){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [nickName]);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="내 정보 수정"
                centerTitle={true}
                homeBtn={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <ItemSection addClass="notPaddingTop">
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="닉네임"
                        placeholder="닉네임"
                        value={nickName}
                        max={null}
                        regexp={null}
                        func={(value)=>{setNickName(value)}}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="휴대폰 번호"
                        placeholder="‘-’ 없이 입력"
                        value={mypageData?.phone ? PhoneSetting(mypageData?.phone) : ""}
                        disabled={true}
                        max={13}
                        regexp={"number"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{}}
                    />
                    <BtnItem
                        addClass="subType line topMargin"
                        contents="휴대폰 번호 변경"
                        disabled={false}
                        func={() => {navigate("/member/edit/phone")}}
                    />
                </ItemSection>
                <ItemSection addClass="notPaddingTop">
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="차량 번호"
                        placeholder="차량 번호"
                        value={mypageData?.number}
                        disabled={true}
                        max={null}
                        regexp={null}
                        func={(value)=>{}}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="차량 제조사"
                        placeholder="차량 제조사"
                        value={mypageData?.maker}
                        disabled={true}
                        max={null}
                        regexp={null}
                        func={(value)=>{}}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="차량 모델"
                        placeholder="차량 모델"
                        value={mypageData?.name}
                        disabled={true}
                        max={null}
                        regexp={null}
                        func={(value)=>{}}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="차량 외형"
                        placeholder="차량 외형"
                        value={mypageData?.category}
                        disabled={true}
                        max={null}
                        regexp={null}
                        func={(value)=>{}}
                    />
                    <BtnItem
                        addClass="subType line topMargin"
                        contents="차량 정보 변경"
                        disabled={false}
                        func={() => {navigate("/member/edit/car")}}
                    />
                </ItemSection>
                <ItemSection>
                    <TextBtnItemBox addClass="withdrawalBox">
                        <TextBtnItem addClass="underLine" contents={"회원 탈퇴"} func={() => {withdrawalOpen()}}/>
                    </TextBtnItemBox>
                </ItemSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"내 정보 저장"}
                        disabled={btnChk}
                        func={() => {editMyData()}}
                    />
                </BtnBox>
            </ContentsSection>
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default Edit;