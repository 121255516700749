import { ToggleBtn } from "component/basic/btns";

//설정 메뉴 섹션 항목
function SettingItem(data){
    return (
        <button type="button" onClick={()=>{data.func()}} style={{cursor : data.cursorType ? data.cursorType : "pointer"}} disabled={data.disabled ? data.disabled : false} className={`settingItem ${data.addClass ? data.addClass : ""}`}>
            <div className="settingItemNameBox">
                <p className={`settingItemName`} dangerouslySetInnerHTML={{__html:data.name}}/>
                {data.nameCaption ? <p className="settingItemIcon" dangerouslySetInnerHTML={{__html:data.nameCaption}}/> : ""}
            </div>
            <div className="settingItemSubItemBox">
                {data.subItemText ? <p className={`menuSubItemText ${data.subItemTextAddClass ? data.subItemTextAddClass : ""}`} dangerouslySetInnerHTML={{__html:data.subItemText}}/> : ""}
                {data.icon ? <img src={`/assets/images/basic/${data.icon}.svg`}/> : ""}
                {data.toggleBtn ? 
                    <ToggleBtn
                        checkedType={data.checkedType}
                        chkFunc={(e)=>{data.chkFunc(e)}}
                        id={data.toggleId}
                    /> : ""    
                }
            </div>
        </button>
    );
}

export {SettingItem};