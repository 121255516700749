import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { DatePicer } from "component/basic/formItems";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, ItemSection, ItemSectionTitle, PageSizing, TimeSelBox } from "component/app/items";
import { Tap } from "component/basic/tap";
import { comFormat, setDateTime } from "js/function";
import { DetailItem, DetailItemBox } from "component/detailing/item";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';
import { NotItems } from "component/basic/notItems";
import { FooterManu } from "component/elements/footer";

const DetailingList = (props) => {
    const navigate = useNavigate();

    const detailingApi = useGet({
        url:`/detailing/product?car_size=${localStorage.getItem("carSize")}`,
        loginType:"login"
    });

    const detailingData = detailingApi?.data?.products||[];

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={false}
                headTitle={null}
            >
                <Tap
                    addClass=""
                    func={(id)=>{
                        navigate(`/${id}`)
                    }}
                    val="detailing"
                    data={[
                        {id:"package",category:"패키지"},
                        {id:"wash",category:"카워시"},
                        {id:"detailing",category:"디테일링"},
                        // {id:"dentrepair",category:"덴트리페어"}
                    ]}
                />
            </Header>
            <ContentsSection addClass="headerContents footerContents">
                {/* <TopLink name="나의 세차 패턴에 맞는 이용권 알아보기" func={()=>{navigate("/member/survey")}}/> */}
                <DetailItemBox>
                    {detailingData && detailingData?.length > 0 ?
                        <>
                            {detailingData?.map((item, i) => (
                                <DetailItem
                                    key={i}
                                    addClass=""
                                    id={item.id}
                                    name={item.name}
                                    caption={item.contents}
                                    requiredTime={item.requiredTime}
                                    service={item.service}
                                    notes={item.notes}
                                    textBtn="AI 디테일링 리포트란?"
                                    textBtnFunc={()=>{navigate("/report/about")}}
                                    btnText={`${comFormat(item.price)}원`}
                                    textType={false}
                                    btnFunc={()=>{navigate(`/detailing/reservation/app/${item.id}`,{state: {productData:item}})}}
                                />
                            ))}
                        </>
                    :
                        <NotItems
                            addClass="min"
                            img="/assets/images/icon/not_event.svg"
                            text="이용가능한 이용권이 없습니다."
                        />
                    }
                </DetailItemBox>
            </ContentsSection>
            <FooterManu menuChk="1"/>
        </PageSizing>
    );
};

export default DetailingList;