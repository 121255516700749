import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ContentsSection, GuideTextBox, ItemSection, ItemSectionTitle, PageSizing, TimeSelBox } from "component/app/items";
import { comFormat, dDay, setDateTime } from "js/function";
import { DetailItem } from "component/detailing/item";
import { DatePicer } from "component/basic/formItems";
import { NotItems } from "component/basic/notItems";
import { LoadingBox, Popup } from "component/basic/popup";

const DetailingReservationEdit = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const [btnChk,setBtnChk] =  useState(true);

    const [month,setMonth] =  useState("");
    const [day,setDay] =  useState("");
    const [time,setTime] =  useState("");
    const [excludeDates,setExcludeDates] =  useState([]);
    const [dateChk,setDateChk] = useState(false);

    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    const [popupData,setPopupData] =  useState(null);

    const [submitChk,setSubmitChk] =  useState(true);

    const [settingChk,setSettingChk] =  useState({
        date:true,
        time:true
    });

    const detailingApi = useGet({
        url:`/detailing/detail?detailing_reservation_id=${id}`,
        loginType:"login"
    });
    const detailingData = detailingApi?.data?.detailing_reservation;

    const detailingDateApi = useGet({
        url:detailingData?.product_id && month ? `/detailing/month?month=${month}&detailing_product_id=${detailingData?.product_id}` : null,
        loginType:"login"
    });
    const detailingDateData = detailingData?.product_id && month ? detailingDateApi?.data?.detailing_reservations : "";

    const detailingTimeApi = useGet({
        url:detailingData?.product_id && month && day ? `/detailing/time?date=${month}-${day}&detailing_product_id=${detailingData?.product_id}` : null,
        loginType:"login"
    });
    const detailingTimeData = detailingData?.product_id && month && day ? detailingTimeApi?.data?.detailing_times||[] : [];

    function editOpen(){
        setPopupData({
            addClass:null,
            title:"예약 변경 확인",
            text:"예약을 변경하시겠습니까?<br/>예약 변경은 단 1회만 가능합니다.",
            closeType:true,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{},
            btn0:"취소",
            btn0Type:"",
            btn1:"예약 변경",
            btn1Type:"",
            btnFunc1:()=>{
                editDetailing();
            }
        });
    }

    function editDetailing(){
        if(submitChk){
            setSubmitChk(false);
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("detailing_reservation_id", id);
            formData.append("date", `${month}-${day}`);
            formData.append("time", time);

            fatchSet.FatchApi({
                type:"POST",
                loginType:"login",
                url: `/detailing/reservation/modify`,
                formDataItem: formData,
                success: (data) => {
                    setSubmitChk(true);
                    setLoadingBoxOpen(false);

                    setPopupData({
                        addClass:null,
                        title:"예약 변경 완료",
                        text:`예약일시 : ${month}-${day} ${time}<br/>요청하신 예약 일정으로 변경 완료되었습니다.`,
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{navigate(-1)},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    setSubmitChk(true);
                    setLoadingBoxOpen(false);

                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                }
            })
        }
    }

    useEffect(() => {
        setLoadingBoxOpen(true);
        setTimeout(() => {
            setLoadingBoxOpen(false);
        }, 3000);
    }, []);

    useEffect(() => {
        if(detailingData){
            setLoadingBoxOpen(false);
        }
    }, [detailingApi]);

    useEffect(() => {
        if(detailingDateData && settingChk?.date){
            if(dDay(detailingData?.date) >= -1){
                setMonth(setDateTime(detailingData?.date,"yymm","hymd"));
                setDay(setDateTime(detailingData?.date,"day",null));

                setDateChk(!dateChk);
            }

            let settingChkEdit = {...settingChk};
            settingChkEdit.date = false;
            setSettingChk(settingChkEdit);
        }
    }, [detailingDateApi]);

    useEffect(() => {
        if(detailingTimeData && settingChk?.time){
            if(detailingTimeData?.filter((el) => el?.time === detailingData?.time && el?.is_reservation !== 1)?.length > 0){
                setTime(detailingData?.time);

                setDateChk(!dateChk);
            }

            let settingChkEdit = {...settingChk};
            settingChkEdit.time = false;
            setSettingChk(settingChkEdit);
        }
    }, [detailingTimeApi]);

    useEffect(() => {
        let nowDate = new Date();
        setMonth(setDateTime(nowDate,"yymm","hymd"));
        // setDay(setDateTime(nowDate,"day",null));

        setDateChk(!dateChk);
    }, []);

    useEffect(() => {
        if(detailingDateData){
            let excludeDateSetting = [];
            detailingDateData?.forEach(function(item,i){
                excludeDateSetting.push(item.date);
                if(detailingDateData?.length - 1 == i){
                    setExcludeDates(excludeDateSetting);
                    setDateChk(!dateChk);
                }
            });
        }
    }, [detailingDateApi]);

    useEffect(() => {
        if(month !== "" && day !== "" && time !== "" && id){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [month,day,time,id]);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="예약 변경하기"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <ItemSection>
                    <DetailItem
                        addClass={detailingData?.status !== 1 ? "isUseType" : ""}
                        id={detailingData?.id}
                        name={detailingData?.name||""}
                        date={detailingData ? `<span class='${detailingData?.status == 1 ? "gTextColor" : ""}'>예약일시 : ${detailingData?.date} ${detailingData?.time}</span>` : ""}
                        caption={detailingData?.contents||""}
                        textBtn={null}
                        textBtnFunc={()=>{}}
                        btnText={detailingData?.status == 1 ? "사용가능" : detailingData?.status == 2 ? "사용완료" : detailingData?.status == 3 ? "취소요청" : detailingData?.status == 4 ? "취소완료" : detailingData?.status == 10 ? "사용불가" : ""}
                        btnFunc={()=>{}}
                        textType={true}
                    />
                </ItemSection>
                <ItemSection>
                    <ItemSectionTitle
                        contents="날짜"
                    />
                    <DatePicer
                        inputName=""
                        placeholder=""
                        value={month && day ? month + "-" + day : null}
                        dataChk={dateChk}
                        minCount={false}
                        inline={true}
                        excludeDates={excludeDates}
                        func={(date) => {
                            setMonth(setDateTime(date,"yymm","hymd"));
                            setDay(setDateTime(date,"day",null));
                            setTime("");
                        }}
                        prevMonth={(date) => {setMonth(date)}}
                        nextMonth={(date) => {setMonth(date)}}
                    />
                </ItemSection>
                <ItemSection>
                    <ItemSectionTitle
                        contents="시간 선택"
                    />
                    {detailingTimeData && detailingTimeData.length > 0 ? 
                        <TimeSelBox
                            addClass=""
                            data={detailingTimeData}
                            func={(val)=>{setTime(val)}}
                            value={time}
                        />
                    :
                        <NotItems
                            addClass="min"
                            img={null}
                            text="날짜를 선택해주세요."
                        />
                    }
                </ItemSection>
                <ItemSection>
                    <GuideTextBox
                        title="안내사항"
                        data={[
                            "뷰티팩토리(디테일링) 예약은 취소/변경이 가능합니다.",
                            "예약 변경 시 별도 수수료 없이 1회에 한해 변경 가능하며, 예약 변경 이후 예약을 취소하실 수 없습니다.",
                            "예약 취소는 아래 취소 수수료 정책에 따라 부과됩니다.",
                            "예약한 일시에 방문하지 않을 경우 이용권은 사용불가 상태로 변경되며 사용불가 이용권은 환불이 불가합니다."
                        ]}
                    />
                    <GuideTextBox
                        title="예약 취소 수수료 안내"
                        data={[
                            "예약일시 7일 이전 = 전액 환불",
                            "예약일시 5일 이전 = 10% 취소 수수료 발생",
                            "예약일시 3일 이전 = 20% 취소 수수료 발생",
                            "예약일시 1일 이전 = 30% 취소 수수료 발생",
                            "당일 취소 시, 100% 취소 수수료"
                        ]}
                    />
                </ItemSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"예약 변경"}
                        disabled={btnChk}
                        func={() => {editOpen()}}
                    />
                </BtnBox>
            </ContentsSection>
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default DetailingReservationEdit;