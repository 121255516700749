import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentsSection, PageSizing } from "component/app/items";
import { RecommendedBadge, RecommendedInfo, RecommendedInfoBox, RecommendedProduct, RecommendedProductBox, RecommendedSection } from "component/board/recommended";
import { BtnItem } from "component/basic/btns";
import { BottomErrMsg, Popup } from "component/basic/popup";

const Recommended = (props) => {
    const navigate = useNavigate();
    const state = useLocation();
    const backData = state?.state?.backData||null;

    const pageDataApi = useGet({
        url:`/event/recommended`,
        loginType:"login"
    });
    const pageData = pageDataApi?.data?.products;
    const recommendCheck = pageDataApi?.data?.recommend_user_id;

    const mypageApi = useGet({
        url:`/mypage`,
        loginType:"login"
    });
    const nicknameData = mypageApi?.data?.user_info?.name;
    const recommendCodeData = mypageApi?.data?.user_info?.recommend_code;

    const copyRef = useRef(null);
    const [bottomMsgData,setBottomMsgData] = useState({chk : 0});
    const [popupData,setPopupData] =  useState(null);

    function restrictedPopup(){
        setPopupData({
            addClass:null,
            title:"친구 초대 불가",
            text:"이미 친구 초대를 완료하셨습니다.",
            closeType:false,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{},
            btn0:"확인",
            btn0Type:"",
            btn1:null,
            btn1Type:"",
            btnFunc1:()=>{}
        });
    }
  
    function shareEv(){
        let varUA = navigator.userAgent;
        if (varUA.match("autonova/Android") != null) {
            window.autonova.shareKakao("109181", localStorage.getItem("token"))
        } else if (varUA.match("autonova/iOS") != null) {
            window.webkit.messageHandlers.shareKakao.postMessage({"messageVarId":"109181","ident":localStorage.getItem("token")});
        }
    }

    function copyText(){
        copyRef.current.select();
        document.execCommand('copy');
        copyRef.current.blur();
        setBottomMsgData({
            text : "클립보드에 복사되었습니다.",
            chk : bottomMsgData.chk + 1
        });
    }

    return (
        <PageSizing addClass="headerPage recommendedPage">
            <Header
                pageBack={backData ? false : true}
                func={() => {navigate(-1)}}
                headTitle="친구 추천하기"
                centerTitle={true}
                homeBtn={true}
            />
            <ContentsSection addClass="headerTapContents bottomBasicPadding">
                <RecommendedSection>
                    <img src="/assets/images/img/recommended_img_big.png" className="recommendedSection_0_img"/>
                    <RecommendedBadge text="EVENT"/>
                    <p className="recommendedSection_0_subTitle">세차를 항상 고민하는 친구에게</p>
                    <h1 className="recommendedSection_0_title">오토노바를 추천해주세요!</h1>
                    <p className="recommendedSection_0_text">내가 추천한 친구/지인이 회원가입 하면 친구에게 오토노바 서비스를 이용할 수 있는 <b>보너스 이용권 지급!</b></p>

                    <RecommendedProductBox>
                    {pageData?.length > 0 && pageData?.map((item,i)=>(
                        <RecommendedProduct
                            key={i}
                            name={item.name}
                            qty={item.qty}
                        />
                    ))}
                        <RecommendedProduct
                            key={'coupon_1'}
                            name={"익스프레스 핸드왁스 10% 할인 쿠폰"}
                            qty={1}
                        />
                    </RecommendedProductBox>
                </RecommendedSection>
                <RecommendedSection>
                    <img src="/assets/images/img/recommended_ev.png" className="recommendedSection_1_img"/>
                    <RecommendedBadge text="이벤트 참여방법"/>
                    <p className="recommendedSection_1_text">아래 <b>[친구 초대링크 공유]</b> 로 친구에게 초대링크를 발송하면 회원가입 시 친구에게 보너스 이용권 지급드려요</p>
                    <div className="recommendedSection_btnBox">
                        <BtnItem
                            addClass="subType dColor"
                            contents="친구 초대링크 공유"
                            disabled={false}
                            func={() => {
                                if(recommendCheck){
                                    restrictedPopup()
                                }else{
                                    shareEv()
                                }
                            }}
                        />
                        <BtnItem
                            addClass="subType dgColor"
                            contents="초대링크 복사하기"
                            disabled={false}
                            func={() => {
                                if(recommendCheck){
                                    restrictedPopup()
                                }else{
                                    copyText()
                                }
                            }}
                        />
                    </div>
                </RecommendedSection>
                <RecommendedInfoBox>
                    <RecommendedInfo
                        name="꼭 확인해주세요!"
                        infoItem={[
                            "친구 초대링크 공유를 통해 지급된 보너스 이용권은 유효기간이 존재합니다.",
                            "지급된 보너스 이용권은 타인(차량)에게 양도가 불가합니다.",
                            "서비스 탈퇴 시 지급받으신 보너스 이용권은 모두 소멸됩니다."
                        ]}
                    />
                    <RecommendedInfo
                        name="주의사항 안내"
                        infoItem={[
                            "본 이벤트는 사전 예고 없이 변경되거나 종료될 수 있습니다.",
                            "본 이벤트에 대한 허위/과정 정보를 게시하거나 이벤트 혜택을 기반으로 타인에게 금전거래를 요구하는 등 이벤트 취지에 맞지 않는 부정행위가 확인될 경우 본 이벤트를 통해 지급된 보너스 이용권 회수 및 이벤트 참여 제한 등 조치가 적용될 수 있습니다."
                        ]}
                    />
                </RecommendedInfoBox>
            </ContentsSection>
            <textarea autoComplete="off" ref={copyRef} value={`${nicknameData} 회원님이 오토노바 앱에 초대했어요.\n\n아래 URL을 통해 오토노바 앱을 설치하고 가입하시면 서비스를 바로 이용하실 수 있는 보너스 이용권을 지급드려요.\n\nhttps://invite.autonova.co.kr/recommended?recommend_code=${recommendCodeData}\n\n* 지급된 이용권은 회원가입 시 자동 지급되며 지급된 이용권은 유효기간이 존재하니 유효기간 만료 전까지 이용권을 사용하세요.`} className="copyInput"/>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default Recommended;