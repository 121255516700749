import { Popup } from "component/basic/popup";
import { PaymentSuccess } from "component/payment/item";
import { getParam } from "js/function";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PaymentSuccessPage = () => {
    const navigate = useNavigate();
    const [paymentSuccessOpen,setPaymentSuccessOpen] =  useState(false);
    const [popupData,setPopupData] =  useState(null);

    const success = getParam("success")||"";
    const return_message = decodeURIComponent(getParam("return_message"))||"";
    const type = getParam("type")||"";
    const id = getParam("id")||"";

    useEffect(() => {
        if(success == "true"){
            if(type == "cash"){
                setPopupData({
                    addClass:null,
                    title:"캐시 충전 완료",
                    text:"요청하신 캐시 충전이 완료되었습니다.",
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{navigate("/")},
                    btn0:"확인",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });
            }else{
                setPaymentSuccessOpen(true);
            }
        }else{
            setPopupData({
                addClass:null,
                title:"결제 실패",
                text:return_message ? return_message : "결제가 실패하였습니다.<br/>잠시 뒤 다시 시도해 주세요.",
                closeType:false,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{navigate("/")},
                btn0:"확인",
                btn0Type:"",
                btn1:"",
                btn1Type:"",
                btnFunc1:()=>{}
            });
        }
    }, []);

    return (
        <div className="pageSizing">
            <PaymentSuccess
                open={paymentSuccessOpen}
                openClose={()=>{setPaymentSuccessOpen(false)}}
            />
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default PaymentSuccessPage;