import { ContentsSection, InputSection, LogoBox, PageSizing } from "component/app/items";
import * as fatchSet from "../../api/api";
import { BtnItem } from "component/basic/btns";
import { SnsBtn } from "component/member/item";
import { getParam, getParam_sns } from "js/function";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "component/elements/header";
import { Popup } from "component/basic/popup";

const WelCome = () => {
    const varUA = navigator.userAgent;
    const navigate = useNavigate();
    const type = getParam("type");
    const recommendedCode = getParam("recommendedCode");
    const [popupData,setPopupData] =  useState(null);

    // 미비사항(sns 로그인 없음)

    //애플
    function appleLoginStart(){
        window.location.href = `https://appleid.apple.com/auth/authorize?client_id=${process.env.REACT_APP_APPLE_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_APPLE_REDIRECT_URL}`;
    }

    //구글
    function googleLoginStart(){
        if (varUA.match("autonova/Android") != null) {
            window.autonova.googleLoginCheck()
        } else if (varUA.match("autonova/iOS") != null) {
            window.webkit.messageHandlers.googleLoginCheckHandler.postMessage("_");
        } else {
            window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URL}&response_type=code&scope=openid email profile`
        }
    }

    //네이버
    function naverLoginStart() {
        if (varUA.match("autonova/Android") != null) {
            window.autonova.naverLoginCheck()
        } else if (varUA.match("autonova/iOS") != null) {
            window.webkit.messageHandlers.naverLoginCheckHandler.postMessage("_");
        } else {
            window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&state=false&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URL}`;
        }
    }

    //카카오
    function kakaoLoginStart() {
        if (varUA.match("autonova/Android") != null) {
            window.autonova.kakaoLoginCheck()
        } else if (varUA.match("autonova/iOS") != null) {
            window.webkit.messageHandlers.kakaoLoginCheckHandler.postMessage("_");
        } else {
            window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URL}&response_type=code`;
        }
    }

    useEffect(() => {
        if(recommendedCode){
            sessionStorage.setItem("recommendedCode",recommendedCode);
        }

        if(type){
            let formData = new FormData();
            if(type == "google"){
                formData.append("code", getParam("code"));
            }
            if(type == "apple"){
                formData.append("code", getParam("code"));
            }
            if(type == "naver"){
                formData.append("code", getParam("code"));
            }
            if(type == "kakao"){
                formData.append("code", getParam("code"));
            }

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/${type}/login`,
                success: (data) => {
                    if(data.data.type == "login"){
                        localStorage.setItem("token",data.data.token.access_token);
                        localStorage.setItem("refresh_token",data.data.token.refresh_token);

                        localStorage.setItem("carSize",data.data.carSize);

                        window.logIn();

                        setTimeout(() => {
                            window.joinChkApi();
                            
                            if(sessionStorage.getItem("setUrl")){
                                let reUrl = sessionStorage.getItem("setUrl");
                                sessionStorage.removeItem("setUrl");
                                navigate(reUrl);
                            }else{
                                navigate(`/`);
                            }
                        }, 100);
                    }else{
                        sessionStorage.setItem("token",data.data.token.access_token);
                        sessionStorage.setItem("refresh_token",data.data.token.refresh_token);

                        navigate("/member/terms/sns");
                    }
                },
                err: (data) => {
                    setPopupData({
                        addClass:null,
                        title:"로그인 실패",
                        text:"일시적인 이유로 로그인에 실패하였습니다.<br/>증상이 지속될 경우 고객센터에 문의해주세요.",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            })
        }
    }, []);

    return (
        <PageSizing>
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle={null}
            />
            <ContentsSection addClass="bottomContents">
                <LogoBox addClass="pageLogo"/>
                <InputSection>
                    <BtnItem
                        addClass="subType"
                        contents="오토노바 계정 로그인"
                        disabled={false}
                        func={() => {navigate("/member/login")}}
                    />
                    <BtnItem
                        addClass="subType line"
                        contents="오토노바 회원가입"
                        disabled={false}
                        func={() => {navigate("/member/terms/email")}}
                    />
                    <div className="welcome_sns">
                        <p className="welcome_sns_text">다른 방법으로 로그인</p>
                    </div>
                    <div className="welcome_snsBtnBox">
                        <SnsBtn
                            img="naver"
                            func={() => {naverLoginStart()}}
                        />
                        <SnsBtn
                            img="kakao"
                            func={() => {kakaoLoginStart()}}
                        />
                        <SnsBtn
                            img="google"
                            func={() => {googleLoginStart()}}
                        />
                        <SnsBtn
                            img="apple"
                            func={() => {appleLoginStart()}}
                        />
                    </div>
                </InputSection>
            </ContentsSection>
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default WelCome;