import { useNavigate } from "react-router-dom";

function SnsBtn(data){
    let navigate = useNavigate();

    return (
        <button type="button" id={data.id||""} className={`snsBtn ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false}>
            <img src={`/assets/images/sns/${data.img}.svg`}/>
        </button>
    );
}

function LoginSubLink(data){
    let navigate = useNavigate();

    return (
        <button type="button" className="loginSubLink" onClick={() => data.func()} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function CarNumber(data){
    let navigate = useNavigate();

    function valSetting(e){
        let value = e;

        if(value){
            if(data.regexp){
                value = value.replace(data.regexp,"");
            }

            value = data.max ? value.slice(0, data.max) : value
        }
        return value ? value : "";
    }

    return (
        <div className="carNumberBox">
             <input type={"text"} inputMode={"text"} pattern={""} value={valSetting(data.value)} className="carNumber" onInput={(e) => {data.func(valSetting(e.target.value))}} onPaste={(e) => {data.func(valSetting(e.target.value))}} onChange={(e) => {data.func(valSetting(e.target.value))}} onKeyUp={(e) => {data.func(valSetting(e.target.value))}} placeholder={data.placeholder && data.placeholder !== "" ? data.placeholder : ""} readOnly={data.readOnly ? data.readOnly : false} disabled={data.disabled ? data.disabled : false}/>
        </div>
    );
}

function CarInfoPopupItem(data){

    return (
        <div className="carInfoPopupDetail_item">
            <p className="carInfoPopupDetail_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            <p className="carInfoPopupDetail_val" dangerouslySetInnerHTML={{__html:data.val}}/>
        </div>
    );
}

function CarInfoPopupContents(data){
    let navigate = useNavigate();

    return (
        <div className="carInfoPopup">
            {data.carImg ? <img src={data.carImg}/> : ""}
            <p className="carInfoPopup_title">고객님의 차량이 맞으신가요?</p>
            <div className="carInfoPopupDetail">
                <CarInfoPopupItem
                    name="제조사"
                    val={data.maker}
                />
                <CarInfoPopupItem
                    name="모델 명"
                    val={data.name}
                />
                <CarInfoPopupItem
                    name="외형"
                    val={data.category}
                />
            </div>
        </div>
    );
}

function MamberPageContents(data){
    let navigate = useNavigate();

    return (
        <div className="mamberPageContentsBox">
            <div className="mamberPageContents_titleBox">
                <h1 className="mamberPageContents_title" dangerouslySetInnerHTML={{__html:data.title}}/>
                <p className="mamberPageContents_subTitle" dangerouslySetInnerHTML={{__html:data.contents}}/>
            </div>
            <img src="/assets/images/img/survey_img.svg"/>
        </div>
    );
}

export {SnsBtn,LoginSubLink,CarNumber,MamberPageContents,CarInfoPopupContents};