import { EditerBox } from "component/app/items";
import { setDateTime } from "js/function";
import { useNavigate } from "react-router-dom";

function NoticeItem(data){
    let navigate = useNavigate();

    return (
        <button type="button" onClick={()=>{navigate(`/notice/detail/${data.id}`)}} className={`noticeItem ${data.addClass||""}`}>
            <div className="noticeItem_titleBox">
                <h1 className="noticeItem_title" dangerouslySetInnerHTML={{__html:data.title}}/>
                {data.date ? <p className="noticeItem_date" dangerouslySetInnerHTML={{__html: setDateTime(data.date,"yymmdd","hymd")}}/> : ""}
            </div>
            {data.icon ? <img src="/assets/images/basic/link_icon.svg"/> : ""}
        </button>
    );
}

function EventItem(data){
    let navigate = useNavigate();

    return (
        <button type="button" onClick={()=>{data.sub_link ? window.location.href = data.sub_link : navigate(`/event/detail/${data.id}`)}} className={`eventItem ${data.addClass||""}`}>
           <div className="eventItem_conetents backgroundImg" style={{"backgroundImage":`url('${data.bgImg}')`}}>
                <span className={`eventItemType ${data.type == "종료" ? "endEvent" : ""}`} dangerouslySetInnerHTML={{__html:data.type}}/>
                {/* <h1 className="eventItem_title" dangerouslySetInnerHTML={{__html:data.title}}/> */}
                {data.text ? <p className="eventItem_text" dangerouslySetInnerHTML={{__html:data.text}}/> : ""}
           </div>
           <p className="eventItem_date">{`${data.start_date && data.start_date !== "0000-00-00" ? setDateTime(data.start_date,"yymmdd","hymd") : ""}${data.start_date && data.start_date !== "0000-00-00" && data.end_date && data.end_date !== "0000-00-00" ? " ~ " : ""}${data.end_date && data.end_date !== "0000-00-00" ? setDateTime(data.end_date,"yymmdd","hymd") : ""}`}</p>
        </button>
    );
}

function DetailTitleBox(data){
    let navigate = useNavigate();

    return (
        <div className={`detailTitleBox backgroundImg ${data.addClass||""}`} style={data.bgImg ? {"backgroundImage":`url('${data.bgImg}')`} : {}}>
            <h1 className="detailTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
            <p className="detailDate" dangerouslySetInnerHTML={{__html:data.date}}/>
        </div>
    );
}

function DetailContents(data){
    let navigate = useNavigate();

    return (
        <EditerBox addClass="detailContents" data={data.contents}/>
    );
}

export {NoticeItem,EventItem,DetailTitleBox,DetailContents};