import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, PageSizing } from "component/app/items";
import { PopupTap, Tap } from "component/basic/tap";
import { FooterManu } from "component/elements/footer";
import { ReportAutolifeBox, ReportBtnBox, ReportCarImgBox, ReportCarInfoContetns, ReportCarMainScore, ReportCarScore, ReportDetailInfo, ReportInfoContetns, ReportSection } from "component/report/item";
import { Swiper, SwiperSlide } from "swiper/react";
import { TopLink, WashListItem } from "component/wash/item";
import { ToastPopup } from "component/basic/popup";
import { DetailItem } from "component/detailing/item";
import { comFormat, reportPopupColorChk, reportPopupTitleColorChk, setDateTime } from "js/function";

const ReportDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const [swiper, setSwiper] = useState(null);
    const [infoToastPopupData,setInfoToastPopupData] =  useState(null);
    const [toastPopupData,setToastPopupData] =  useState(null);
    const [slideTap,setSlideTap] =  useState(0);

    const [dataType,setDataType] =  useState("sample");

    const pageDataApi = useGet({
        url: `/detailing/report/detail?detailing_report_id=${id == "new" ? "" : id}`,
        loginType:"login"
    });
    const pageData = pageDataApi?.data?.detailing_report;

    const [slideData,setSlideData] =  useState({});

    function infoPopupOpen(type){
        setInfoToastPopupData({
            addClass:"notBtn",
            title:`${type == "autoLife" ? "오토라이프 점수" : type == "paintFilm" ? "도막 두께" : type == "luster" ? "광택도 점수" : ""} <img src='/assets/images/icon/info_big.svg'/>`,
            closeType:true,
            closeFuncChk:false,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{},
            btn0:"",
            btn0Type:"",
            btn1:"",
            btn1Type:"",
            btnFunc1:()=>{},
            textBtn:"확인",
            textBtnFunc:()=>{},
            openType:type
        });
    }

    function carInfoPopupOpen(){
        setToastPopupData({
            addClass:"notBtn",
            title:null,
            closeType:true,
            closeFuncChk:true,
            closeFunc:(e) => {if(swiper){swiper.slideTo(0)}},
            btnFunc0:(e)=>{},
            btn0:"",
            btn0Type:"",
            btn1:"",
            btn1Type:"",
            btnFunc1:()=>{},
            textBtn:"",
            textBtnFunc:()=>{}
        });
    }

    function slideDataSetting(type){
        let settingData = {
            slideTitleColor:{
                slide0Color : type === "sample" ? "" : reportPopupTitleColorChk([
                    reportPopupColorChk("luster60",pageData?.d1_luster_60,pageData?.d1_paint_film),
                    reportPopupColorChk("paintFilm",pageData?.d1_luster_60,pageData?.d1_paint_film),
                    reportPopupColorChk("luster60",pageData?.d2_luster_60,pageData?.d2_paint_film),
                    reportPopupColorChk("paintFilm",pageData?.d2_luster_60,pageData?.d2_paint_film),
                    reportPopupColorChk("luster60",pageData?.d3_luster_60,pageData?.d3_paint_film),
                    reportPopupColorChk("paintFilm",pageData?.d3_luster_60,pageData?.d3_paint_film),
                    reportPopupColorChk("luster60",pageData?.d4_luster_60,pageData?.d4_paint_film),
                    reportPopupColorChk("paintFilm",pageData?.d4_luster_60,pageData?.d4_paint_film)
                ]),
                slide1Color : type === "sample" ? "" : reportPopupTitleColorChk([
                    reportPopupColorChk("luster60",pageData?.a1_luster_60,pageData?.a1_paint_film),
                    reportPopupColorChk("paintFilm",pageData?.a1_luster_60,pageData?.a1_paint_film),
                    reportPopupColorChk("luster60",pageData?.a2_luster_60,pageData?.a2_paint_film),
                    reportPopupColorChk("paintFilm",pageData?.a2_luster_60,pageData?.a2_paint_film),
                    reportPopupColorChk("luster60",pageData?.a3_luster_60,pageData?.a3_paint_film),
                    reportPopupColorChk("paintFilm",pageData?.a3_luster_60,pageData?.a3_paint_film),
                    reportPopupColorChk("luster60",pageData?.a4_luster_60,pageData?.a4_paint_film),
                    reportPopupColorChk("paintFilm",pageData?.a4_luster_60,pageData?.a4_paint_film)
                ]),
                slide2Color : type === "sample" ? "" : reportPopupTitleColorChk([
                    reportPopupColorChk("luster60",pageData?.b1_luster_60,pageData?.b1_paint_film),
                    reportPopupColorChk("paintFilm",pageData?.b1_luster_60,pageData?.b1_paint_film),
                    reportPopupColorChk("luster60",pageData?.b2_luster_60,pageData?.b2_paint_film),
                    reportPopupColorChk("paintFilm",pageData?.b2_luster_60,pageData?.b2_paint_film)
                ]),
                slide3Color : type === "sample" ? "rColor" : reportPopupTitleColorChk([
                    reportPopupColorChk("luster60",pageData?.t1_luster_60,pageData?.t1_paint_film),
                    reportPopupColorChk("paintFilm",pageData?.t1_luster_60,pageData?.t1_paint_film),
                    reportPopupColorChk("luster60",pageData?.t2_luster_60,pageData?.t2_paint_film),
                    reportPopupColorChk("paintFilm",pageData?.t2_luster_60,pageData?.t2_paint_film),
                    reportPopupColorChk("luster60",pageData?.t3_luster_60,pageData?.t3_paint_film),
                    reportPopupColorChk("paintFilm",pageData?.t3_luster_60,pageData?.t3_paint_film)
                ])
            },
            slideCarImgColor:{
                t1Color : type === "sample" ? "rColor" : reportPopupColorChk("imgColor",pageData?.t1_luster_60,pageData?.t1_paint_film),
                t2Color : type === "sample" ? "rColor" : reportPopupColorChk("imgColor",pageData?.t2_luster_60,pageData?.t2_paint_film),
                t3Color : type === "sample" ? "rColor" : reportPopupColorChk("imgColor",pageData?.t3_luster_60,pageData?.t3_paint_film),
                d4Color : type === "sample" ? "rColor" : reportPopupColorChk("imgColor",pageData?.d4_luster_60,pageData?.d4_paint_film),
                d3Color : type === "sample" ? "rColor" : reportPopupColorChk("imgColor",pageData?.d3_luster_60,pageData?.d3_paint_film),
                d2Color : type === "sample" ? "" : reportPopupColorChk("imgColor",pageData?.d2_luster_60,pageData?.d2_paint_film),
                d1Color : type === "sample" ? "rColor" : reportPopupColorChk("imgColor",pageData?.d1_luster_60,pageData?.d1_paint_film),
                b2Color : type === "sample" ? "" : reportPopupColorChk("imgColor",pageData?.b2_luster_60,pageData?.b2_paint_film),
                b1Color : type === "sample" ? "yColor" : reportPopupColorChk("imgColor",pageData?.b1_luster_60,pageData?.b1_paint_film),
                a4Color : type === "sample" ? "" : reportPopupColorChk("imgColor",pageData?.a4_luster_60,pageData?.a4_paint_film),
                a3Color : type === "sample" ? "yColor" : reportPopupColorChk("imgColor",pageData?.a3_luster_60,pageData?.a3_paint_film),
                a2Color : type === "sample" ? "yColor" : reportPopupColorChk("imgColor",pageData?.a2_luster_60,pageData?.a2_paint_film),
                a1Color : type === "sample" ? "rColor" : reportPopupColorChk("imgColor",pageData?.a1_luster_60,pageData?.a1_paint_film)
            }, 
            slideDataItems : [{
                img:"car_0",
                data:[
                    {
                        name:"D1 앞 펜더 & 휠 하우스",
                        luster60: type === "sample" ? "87" : pageData?.d1_luster_60||0,
                        paintFilm: type === "sample" ? "81" : pageData?.d1_paint_film||0,
                        luster60Color: type === "sample" ? "" : reportPopupColorChk("luster60",pageData?.d1_luster_60,pageData?.d1_paint_film),
                        paintFilmColor: type === "sample" ? "rColor" : reportPopupColorChk("paintFilm",pageData?.d1_luster_60,pageData?.d1_paint_film)
                    },
                    {
                        name:"D2 운전석 전면 도어",
                        luster60: type === "sample" ? "86" : pageData?.d2_luster_60||0,
                        paintFilm: type === "sample" ? "124" : pageData?.d2_paint_film||0,
                        luster60Color: type === "sample" ? "" : reportPopupColorChk("luster60",pageData?.d2_luster_60,pageData?.d2_paint_film),
                        paintFilmColor: type === "sample" ? "" : reportPopupColorChk("paintFilm",pageData?.d2_luster_60,pageData?.d2_paint_film)
                    },
                    {
                        name:"D3 운전석 후면 도어",
                        luster60: type === "sample" ? "88" : pageData?.d3_luster_60||0,
                        paintFilm: type === "sample" ? "89" : pageData?.d3_paint_film||0,
                        luster60Color: type === "sample" ? "" : reportPopupColorChk("luster60",pageData?.d3_luster_60,pageData?.d3_paint_film),
                        paintFilmColor: type === "sample" ? "rColor" : reportPopupColorChk("paintFilm",pageData?.d3_luster_60,pageData?.d3_paint_film)
                    },
                    {
                        name:"D4 뒤 펜더 & 휠 하우스",
                        luster60: type === "sample" ? "89" : pageData?.d4_luster_60||0,
                        paintFilm: type === "sample" ? "109" : pageData?.d4_paint_film||0,
                        luster60Color: type === "sample" ? "" : reportPopupColorChk("luster60",pageData?.d4_luster_60,pageData?.d4_paint_film),
                        paintFilmColor: type === "sample" ? "rColor" : reportPopupColorChk("paintFilm",pageData?.d4_luster_60,pageData?.d4_paint_film)
                    }
                ]
            },
            {
                img:"car_1",
                data:[
                    {
                        name:"A1 앞 펜더 & 휠 하우스",
                        luster60: type === "sample" ? "86" : pageData?.a1_luster_60||0,
                        paintFilm: type === "sample" ? "89" : pageData?.a1_paint_film||0,
                        luster60Color: type === "sample" ? "" : reportPopupColorChk("luster60",pageData?.a1_luster_60,pageData?.a1_paint_film),
                        paintFilmColor: type === "sample" ? "rColor" : reportPopupColorChk("paintFilm",pageData?.a1_luster_60,pageData?.a1_paint_film)
                    },
                    {
                        name:"A2 보조석 전면 도어",
                        luster60: type === "sample" ? "85" : pageData?.a2_luster_60||0,
                        paintFilm: type === "sample" ? "127" : pageData?.a2_paint_film||0,
                        luster60Color: type === "sample" ? "yColor" : reportPopupColorChk("luster60",pageData?.a2_luster_60,pageData?.a2_paint_film),
                        paintFilmColor: type === "sample" ? "" : reportPopupColorChk("paintFilm",pageData?.a2_luster_60,pageData?.a2_paint_film)
                    },
                    {
                        name:"A3 보조석 후면 도어",
                        luster60: type === "sample" ? "85" : pageData?.a3_luster_60||0,
                        paintFilm: type === "sample" ? "128" : pageData?.a3_paint_film||0,
                        luster60Color: type === "sample" ? "yColor" : reportPopupColorChk("luster60",pageData?.a3_luster_60,pageData?.a3_paint_film),
                        paintFilmColor: type === "sample" ? "" : reportPopupColorChk("paintFilm",pageData?.a3_luster_60,pageData?.a3_paint_film)
                    },
                    {
                        name:"A4 뒤 펜더 & 휠 하우스",
                        luster60: type === "sample" ? "86" : pageData?.a4_luster_60||0,
                        paintFilm: type === "sample" ? "125" : pageData?.a4_paint_film||0,
                        luster60Color: type === "sample" ? "" : reportPopupColorChk("luster60",pageData?.a4_luster_60,pageData?.a4_paint_film),
                        paintFilmColor: type === "sample" ? "" : reportPopupColorChk("paintFilm",pageData?.a4_luster_60,pageData?.a4_paint_film)
                    }
                ]
            },
            {
                img:"car_2",
                data:[
                    {
                        name:"B1 운전석 본 네트",
                        luster60: type === "sample" ? "81" : pageData?.b1_luster_60||0,
                        paintFilm: type === "sample" ? "129" : pageData?.b1_paint_film||0,
                        luster60Color: type === "sample" ? "yColor" : reportPopupColorChk("luster60",pageData?.b1_luster_60,pageData?.b1_paint_film),
                        paintFilmColor: type === "sample" ? "" : reportPopupColorChk("paintFilm",pageData?.b1_luster_60,pageData?.b1_paint_film)
                    },
                    {
                        name:"B2 보조석 본 네트",
                        luster60: type === "sample" ? "87" : pageData?.b2_luster_60||0,
                        paintFilm: type === "sample" ? "130" : pageData?.b2_paint_film||0,
                        luster60Color: type === "sample" ? "" : reportPopupColorChk("luster60",pageData?.b2_luster_60,pageData?.b2_paint_film),
                        paintFilmColor: type === "sample" ? "" : reportPopupColorChk("paintFilm",pageData?.b2_luster_60,pageData?.b2_paint_film)
                    }
                ]
            },
            {
                img:"car_3",
                data:[
                    {
                        name:"T1 앞 루프 판넬",
                        luster60: type === "sample" ? "89" : pageData?.t1_luster_60||0,
                        paintFilm: type === "sample" ? "110" : pageData?.t1_paint_film||0,
                        luster60Color: type === "sample" ? "" : reportPopupColorChk("luster60",pageData?.t1_luster_60,pageData?.t1_paint_film),
                        paintFilmColor: type === "sample" ? "rColor" : reportPopupColorChk("paintFilm",pageData?.t1_luster_60,pageData?.t1_paint_film)
                    },
                    {
                        name:"T2 뒤 루프 판넬",
                        luster60: type === "sample" ? "80" : pageData?.t2_luster_60||0,
                        paintFilm: type === "sample" ? "118" : pageData?.t2_paint_film||0,
                        luster60Color: type === "sample" ? "yColor" : reportPopupColorChk("luster60",pageData?.t2_luster_60,pageData?.t2_paint_film),
                        paintFilmColor: type === "sample" ? "rColor" : reportPopupColorChk("paintFilm",pageData?.t2_luster_60,pageData?.t2_paint_film)
                    },
                    {
                        name:"T3 트렁크",
                        luster60: type === "sample" ? "84" : pageData?.t3_luster_60||0,
                        paintFilm: type === "sample" ? "118" : pageData?.t3_paint_film||0,
                        luster60Color: type === "sample" ? "yColor" : reportPopupColorChk("luster60",pageData?.t3_luster_60,pageData?.t3_paint_film),
                        paintFilmColor: type === "sample" ? "rColor" : reportPopupColorChk("paintFilm",pageData?.t3_luster_60,pageData?.t3_paint_film)
                    }
                ]
            }
        ]};

        setSlideData(settingData);
        if(swiper){
            swiper.slideTo(0);
        }
    }

    useEffect(() => {
        if(pageData){
            
        }
    }, [slideTap]);

    useEffect(() => {
        if(pageData?.id){
            slideDataSetting(null);
            setDataType("dataType");
        }else{
            slideDataSetting("sample");
            setDataType("sample");
        }
    }, [pageDataApi]);

    return (
        <PageSizing addClass="headerPage">
            {id === "new" ? 
            <Header
                pageBack={false}
                headTitle={null}
            >
                <Tap
                    addClass=""
                    func={(id)=>{
                        navigate(`/${id}`)
                    }}
                    val="report/detail/new"
                    data={[
                        {id:"report/about",category:"소개"},
                        {id:"report/detail/new",category:"리포트"},
                        {id:"report/list",category:"내역"}
                    ]}
                />
            </Header>
            :
            <Header
                pageBack={false}
                func={() => {navigate(-1)}}
                headTitle={`${pageData?.created_at ? `<span class="gTextColor">${setDateTime(pageData?.created_at,"yymmdd","hymd")}</span> ` : ""}리포트 결과`}
                centerTitle={true}
                pageClose={true}
                closeFunc={()=>{
                    navigate(-1)
                }}
            />
            }
            <ContentsSection addClass={`headerContents ${id === "new" ? "footerContents" : "bottomBasicPadding"}`}>
                {dataType === "sample" ?
                    <TopLink name="AI 디테일링 샘플 리포트" subText="디테일링 시작하기" func={()=>{navigate("/detailing")}}/>
                    : ""
                }
                <ReportSection>
                    <ReportAutolifeBox
                        addClass=""
                        car_number={dataType === "sample" ? null : pageData?.car_number}
                        rank_type={dataType === "sample" ? "상위" : pageData?.rank_type}
                        rank={dataType === "sample" ? "24%" : pageData?.rank}
                        total_autolife_score={dataType === "sample" ? "760" : pageData?.total_autolife_score}
                        total_comment={dataType === "sample" ? "차량이 대체적으로 준수한 상태로 관리되고 있지만,<br/>도장면 코팅 작업, 고착된 오염물 제거 작업이 필요해요" : pageData?.total_comment}
                        func={()=>{infoPopupOpen("autoLife")}}
                    />
                    <ReportCarScore
                        data={[
                            {
                                name:"평균 도막두께",
                                score:`${dataType === "sample" ? 116 : pageData?.paint_film_avg||0} ㎛`,
                                func:()=>{infoPopupOpen("paintFilm")}
                            },
                            {
                                name:"평균 광택도",
                                score:`${dataType === "sample" ? 85.3 : pageData?.luster_avg||0} GU`,
                                func:()=>{infoPopupOpen("luster")}
                            }
                        ]}
                    />
                </ReportSection>
                <ReportSection title="프로 디테일러 피드백">
                    {pageData?.scratch_detailing_score || pageData?.scratch_detailing_score == 0 || dataType === "sample" ? 
                        <ReportDetailInfo
                            type={dataType}
                            id="0"
                            icon={"feedback_icon_re_0"}
                            title="스크래치"
                            detailing_score={dataType === "sample" ? "250" : pageData?.scratch_detailing_score}
                            img_0={pageData?.scratch_image_url}
                            comment={pageData?.scratch_comment}
                            func={()=>{}}
                        />
                    :""}
                    {pageData?.paint_film_detailing_score || pageData?.paint_film_detailing_score == 0 || dataType === "sample" ? 
                        <ReportDetailInfo
                            type={dataType}
                            id="1"
                            icon={"feedback_icon_re_1"}
                            title="도막두께 상태"
                            detailing_score={dataType === "sample" ? "125" : pageData?.paint_film_detailing_score}
                            img_0={pageData?.paint_film_image_url}
                            comment={pageData?.paint_film_comment}
                            func={()=>{}}
                        />
                    :""}
                    {pageData?.contaminant_detailing_score || pageData?.contaminant_detailing_score == 0 || dataType === "sample" ? 
                        <ReportDetailInfo
                            type={dataType}
                            id="2"
                            icon={"feedback_icon_re_2"}
                            title="오염물 고착 상태"
                            detailing_score={dataType === "sample" ? "50" : pageData?.contaminant_detailing_score}
                            img_0={pageData?.contaminant_image_url}
                            comment={pageData?.contaminant_comment}
                            func={()=>{}}
                        />
                    :""}
                    {pageData?.repair_detailing_score || pageData?.repair_detailing_score == 0 || dataType === "sample" ? 
                        <ReportDetailInfo
                            type={dataType}
                            id="3"
                            icon={"feedback_icon_re_3"}
                            title="보수도장 상태"
                            detailing_score={dataType === "sample" ? "100" : pageData?.repair_detailing_score}
                            img_0={pageData?.repair_image_url}
                            comment={pageData?.repair_comment}
                            func={()=>{}}
                        />
                    :""}
                </ReportSection>
                <ReportSection title="파츠 별 측정 결과">
                    <ReportCarImgBox
                        caption={true}
                        img="car_all"
                        func={()=>{carInfoPopupOpen()}}
                    />
                </ReportSection>
                {dataType !== "sample" && (pageData?.product?.product_type === "wash" || pageData?.product?.product_type === "package" || pageData?.product?.product_type === "detailing") ? 
                    <ReportSection title="추천 상품">
                        {pageData?.product?.product_type === "wash" ? 
                            <WashListItem 
                                id={pageData?.product?.id}
                                name={pageData?.product?.name||""}
                                badges={pageData?.product?.product_badges||""}
                                caption={pageData?.product?.contents||""}
                                option={pageData?.product?.product_options||[]}
                                subOptionTitle={pageData?.product?.relation_product_name||null}
                                subOption={pageData?.product?.relation_product_options||[]}
                                include_inside_full_care={pageData?.product?.include_inside_full_care}
                                inside_full_care_option_count={pageData?.product?.inside_full_care_option_count}
                                use_once={pageData?.product?.use_once}
                                use_month={pageData?.product?.use_month}
                                price={pageData?.product?.price}
                                month_price={pageData?.product?.month_price}
                                original_price={pageData?.product?.original_price}
                            />
                        : pageData?.product?.product_type === "package" ? 
                            <WashListItem
                                id={pageData?.product?.id}
                                name={pageData?.product?.name||""}
                                badges={pageData?.product?.product_badges||""}
                                type={"package"}
                                caption={pageData?.product?.contents||""}
                                packageItems={pageData?.product?.product_packages||[]}
                                option={pageData?.product?.product_options||[]}
                                subOptionTitle={pageData?.product?.relation_product_name||null}
                                subOption={pageData?.product?.relation_product_options||[]}
                                include_inside_full_care={pageData?.product?.include_inside_full_care}
                                inside_full_care_option_count={pageData?.product?.inside_full_care_option_count}
                                use_once={pageData?.product?.use_once}
                                use_month={pageData?.product?.use_month}
                                price={pageData?.product?.price}
                                originalPrice={pageData?.product?.original_price}
                                percent={pageData?.product?.percent}
                                month_price={pageData?.product?.month_price}
                            />
                        : pageData?.product?.product_type === "detailing" ?
                            <DetailItem
                                addClass=""
                                id={pageData?.product?.id}
                                name={pageData?.product?.name}
                                caption={pageData?.product?.contents}
                                requiredTime={pageData?.product?.requiredTime}
                                service={pageData?.product?.service}
                                notes={pageData?.product?.notes}
                                textBtn="AI 디테일링 리포트란?"
                                textBtnFunc={()=>{navigate("/report/about")}}
                                btnText={`${comFormat(pageData?.product?.price)}원`}
                                textType={false}
                                btnFunc={()=>{navigate(`/detailing/reservation/app/${pageData?.product?.id}`,{state: {productData:pageData?.product}})}}
                            />
                        :""}
                    </ReportSection>
                :""}
                {id === "new" ? 
                <ReportBtnBox
                    text={"오토노바의 특별한 디테일링을 바로 시작해보세요"}
                    contents={"디테일링 시작"}
                    func={()=>{
                        navigate("/detailing")
                    }}
                />
                :""}
            </ContentsSection>
            {id === "new" ? 
            <FooterManu menuChk="3"/>
            :""}
            <ToastPopup
                data={infoToastPopupData}
            >
                {
                    infoToastPopupData?.openType == "autoLife" ? 
                        <ReportInfoContetns
                            type="autoLife"
                            title="오토라이프 점수는 고객님의<br/><span class='gTextColor'>차량 컨디션에 따라 부여되는 점수</span>입니다."
                            text="오토노바는 오토라이프 점수에 따라 차량 컨디션을 판단해요.<br/>점수가 아래 기준에 해당할 경우 오토노바 구독권 이용 시 매 월 결제 금액을 할인해 드립니다."
                            subText={[
                                "할인은 정기권에 한해 적용되며 일반 이용권은 해당되지 않습니다.",
                                "할인은 오토라이프 점수가 갱신된 날로부터 90일 간 유효합니다."
                            ]}
                            total_autolife_score={dataType === "sample" ? "760" : pageData?.total_autolife_score}
                            table={true}
                        />
                    : infoToastPopupData?.openType == "paintFilm" ? 
                        <ReportInfoContetns
                            title="도막 두께는<br/>차량의 <span class='gTextColor'>도장 상태를 판단할 수 있는 지표</span>입니다."
                            text="도막 측정기를 통해 확인된 도막두께는 정기적인 디테일링을 위해 참고할 수 있는 지표로서 평균적으로 높은 두막두께를 가졌다면 클리어 코트 층이 높다고 판단할 수 있습니다.<br/><br/>클리어 코트 층이 높다면 디테일링 중 폴리싱의 위험도가 적으며 평균 도막두께 대비 특정 부위에 도막두께가 높다면 사고 등으로 인한 추가/보수 도장을 의심할 수 있습니다."
                            subText={[
                                "도막 두께는 제조사/차종/도장 방식에 따라 상이할 수 있습니다."
                            ]}
                            table={false}
                        />
                    : infoToastPopupData?.openType == "luster" ? 
                        <ReportInfoContetns
                            title="광택도 점수는<br/>차량의 <span class='gTextColor'>노후 상태를 판단할 수 있는 지표</span>입니다."
                            text="통상적으로 차량을 출고했을 때 광택도 점수는 80점을 상회합니다. 출고 후 1~3년이 지났을 때는 지속적인 관리를 하지 않았을 때 평균적으로 50점 내외로 나타나게 됩니다.<br/><br/>차량을 출고 상태와 같이 유지하기 위해서는 정기적인 관리가필요하며, 광택도 점수가 낮은 경우 디테일링 케어를권장드립니다."
                            subText={null}
                            table={false}
                        />
                    : ""
                }
            </ToastPopup>
            <ToastPopup
                data={toastPopupData}
                header={<PopupTap
                    addClass=""
                    func={(id)=>{
                        setSlideTap(id)
                        if(swiper){
                            swiper.slideTo(id)
                        }
                    }}
                    val={slideTap}
                    data={[
                        {id:0,category:`<span class="${slideData?.slideTitleColor?.slide0Color||""}">운전석</span>${slideData?.slideTitleColor?.slide0Color === "" ? "" : `<img src="/assets/images/icon/${slideData?.slideTitleColor?.slide0Color === "rColor" ? "err_r.svg" : "err_y.svg"}"/>`}`},
                        {id:1,category:`<span class="${slideData?.slideTitleColor?.slide1Color||""}">보조석</span>${slideData?.slideTitleColor?.slide1Color === "" ? "" : `<img src="/assets/images/icon/${slideData?.slideTitleColor?.slide1Color === "rColor" ? "err_r.svg" : "err_y.svg"}"/>`}`},
                        {id:2,category:`<span class="${slideData?.slideTitleColor?.slide2Color||""}">본네트</span>${slideData?.slideTitleColor?.slide2Color === "" ? "" : `<img src="/assets/images/icon/${slideData?.slideTitleColor?.slide2Color === "rColor" ? "err_r.svg" : "err_y.svg"}"/>`}`},
                        {id:3,category:`<span class="${slideData?.slideTitleColor?.slide3Color||""}">루프&트렁크</span>${slideData?.slideTitleColor?.slide3Color === "" ? "" : `<img src="/assets/images/icon/${slideData?.slideTitleColor?.slide3Color === "rColor" ? "err_r.svg" : "err_y.svg"}"/>`}`}
                    ]}
                />}
            >
                <div className="reportCarInfoSlide">
                <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    autoHeight={true}
                    loop={true}
                    onSwiper={setSwiper}
                    onRealIndexChange={(e) => {
                        setSlideTap(e.realIndex)
                    }}
                >
                    {slideData?.slideDataItems && slideData?.slideDataItems?.map((item, i) => (
                        <SwiperSlide key={i}>
                            <ReportCarInfoContetns
                                img={item.img}
                                data={item.data}

                                t1Color={slideData?.slideCarImgColor?.t1Color}
                                t2Color={slideData?.slideCarImgColor?.t2Color}
                                t3Color={slideData?.slideCarImgColor?.t3Color}
                                d4Color={slideData?.slideCarImgColor?.d4Color}
                                d3Color={slideData?.slideCarImgColor?.d3Color}
                                d2Color={slideData?.slideCarImgColor?.d2Color}
                                d1Color={slideData?.slideCarImgColor?.d1Color}
                                b2Color={slideData?.slideCarImgColor?.b2Color}
                                b1Color={slideData?.slideCarImgColor?.b1Color}
                                a4Color={slideData?.slideCarImgColor?.a4Color}
                                a3Color={slideData?.slideCarImgColor?.a3Color}
                                a2Color={slideData?.slideCarImgColor?.a2Color}
                                a1Color={slideData?.slideCarImgColor?.a1Color}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                </div>
            </ToastPopup>
        </PageSizing>
    );
};

export default ReportDetail;