import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { ChkBox, InputItemBox } from "component/basic/formItems";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, EditerBox, ItemSection, ItemSectionTitle, PageSizing } from "component/app/items";
import { BottomErrMsg, LoadingBox, Popup, RightPopup } from "component/basic/popup";
import { PaymentBox, PaymentItem, PaymentSuccess } from "component/payment/item";
import { PointAddBtnBox } from "component/mypage/point";

const CashAdd = (props) => {
    const navigate = useNavigate();

    const termsApi = useGet({
        url:"/term?type=pay",
    });
    const termsData = termsApi?.data?.terms;

    const cashApi = useGet({
        url:`/cash/remain`,
        loginType:"login"
    });
    const cashData = cashApi?.data?.cash;

    const [paymentType,setPaymentType] =  useState("CARD");

    const [price,setPrice] =  useState(0);

    const [checkItems, setCheckItems] = useState([]);
    const [checkItemTitles, setCheckItemTitles] = useState([]);
    const [terms,setTerms] =  useState({});
    const [rightOpenType,setRightOpenType] =  useState(false);

    const [popupData,setPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);

    const [btnChk,setBtnChk] =  useState(true);

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const [paymentSuccessOpen,setPaymentSuccessOpen] =  useState(false);

    const termsDetailApi = useGet({
        url: terms?.id ? `/term/detail?term_id=${terms?.id}` : null,
    });
    const termsDetailData = termsDetailApi?.data?.term;

    const paymentList = [{
        id: "CARD",
        name: `신용/체크카드`,
        icon: "/assets/images/icon/pay_cart.svg",
        disabled:false
    },/*{
        id: "NAVER",
        name: `네이버페이`,
        icon: "/assets/images/icon/pay_naver.svg",
        disabled:false
    },{
        id: "KAKAO",
        name: `카카오페이`,
        icon: "/assets/images/icon/pay_kakao.svg",
        disabled:false
    }*/]
    
    // 체크박스 선택
    const handleSingleCheck = (checked, id, text) => {
         if (checked) {
            setCheckItemTitles(prev => [...prev, text]);
            setCheckItems(prev => [...prev, id]);
        } else {
            setCheckItemTitles(checkItemTitles.filter((el) => el !== text));
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {
        const idArr = [],
            textArr = [];
            termsData?.forEach((el) => {idArr.push(el.id);textArr.push(el.term_title)});
            setCheckItemTitles(textArr);
            setCheckItems(idArr);
        }
        else {
            setCheckItemTitles([]);
            setCheckItems([]);
        }
    }

    const rightOpen = (id,title) => {
        let termsItem = {
            id:id,
            title:title
        }
        setTerms(termsItem);
        setRightOpenType(true);
    }

    function payment(){
        if(!loadingBoxOpen){
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("price", price);
            formData.append("payment_method", paymentType);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/cash/charge/register`,
                success: (data) => {
                    let payId = data?.data?.user_cash_id;

                    setLoadingBoxOpen(false);

                    window.location.href = `${process.env.REACT_APP_PAYMENT_URL}/api/cash/charge?user_cash_id=${payId}&access_token=${localStorage.getItem("token")}`;
                },
                err: (data) => {
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    useEffect(() => {
        let chkSet = [...checkItems];
        if(chkSet.length == termsData?.length && price > 0){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [checkItems,price]);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="캐시 충전"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <ItemSection addClass="notPaddingTop">
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="충전 금액"
                        placeholder="충전 금액"
                        value={price}
                        numberMax={Number(cashData) >= 500000 ? 0 : 500000 - Number(cashData)}
                        numberMaxErrFunc={()=>{
                            setBottomMsgData({
                                text : "캐시 충전 한도 50만원을 초과하였습니다.",
                                chk : bottomMsgData.chk + 1
                            });
                        }}
                        max={null}
                        regexp={"number"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{setPrice(value)}}
                    />
                    <PointAddBtnBox
                        func={(value)=>{
                            let maxVal = Number(cashData) >= 500000 ? 0 : 500000 - Number(cashData);
                            let setVal = value;
                            if(maxVal || maxVal === 0){
                                if(Number(value) > Number(maxVal)){
                                    setBottomMsgData({
                                        text : "캐시 충전 한도 50만원을 초과하였습니다.",
                                        chk : bottomMsgData.chk + 1
                                    });
                                }
                                setVal = Number(setVal) > Number(maxVal) ? String(maxVal) : String(setVal)
                            }
                            setPrice(setVal)
                        }}
                    />
                </ItemSection>
                <ItemSection>
                    <ItemSectionTitle
                        contents={"결제수단"}
                    />
                    <PaymentBox>
                        {paymentList.map((item,i)=>(
                            <PaymentItem
                                key={i}
                                id={item.id}
                                name={item.name}
                                icon={item.icon}
                                disabled={item.disabled}
                                activeChk={paymentType}
                                func={(val)=>{setPaymentType(val)}}
                            />
                        ))}
                    </PaymentBox>
                </ItemSection>
                
                <ItemSection>
                    <ChkBox
                        addClass="allChkItem"
                        func={(e) => handleAllCheck(e.target.checked)}
                        checkedType={checkItems?.length === termsData?.length ? true : false}
                        // chkSubType=""
                        label="모든 약관에 동의합니다."

                    />
                    {termsData?.map((item, i) => (
                        <ChkBox
                            key={i}
                            addClass=""
                            func={(e) => handleSingleCheck(e.target.checked,item.id,item.title)}
                            checkedType={checkItems.includes(item.id) ? true : false}
                            label={`(필수) ${item.title}`}
                            chkFunc={(e) => rightOpen(item.id,item.title)}
                        />
                    ))}
                </ItemSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"캐시 충전"}
                        disabled={btnChk}
                        func={() => {payment()}}
                    />
                </BtnBox>
            </ContentsSection>
            <RightPopup
                topTitle={terms?.title}
                addClass={rightOpenType ? "active bottomBtn" : " bottomBtn"}
                closePopup={() => {setTerms({});setRightOpenType(false)}}
                openType={rightOpenType}
                bType={true}
            >
                <EditerBox addClass="termsText" data={termsDetailData?.contents}/>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents="확인하고 동의합니다."
                        disabled={false}
                        func={() => {
                            if(!checkItems.includes(terms?.id)){
                                handleSingleCheck(true,terms?.id,terms?.title);
                            };
                        setTerms({});setRightOpenType(false)}}
                    />
                </BtnBox>
            </RightPopup>
            <PaymentSuccess
                open={paymentSuccessOpen}
                openClose={()=>{setPaymentSuccessOpen(false)}}
            />
            <Popup
                data={popupData}
            />
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default CashAdd;