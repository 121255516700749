import { slideToggle, slideUp } from "js/function";
import { useEffect, useRef, useState } from "react";

//아코디언 목록
function AccordionSection(data){
    return (
        <div className={`accordionSection ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function AccordionItem(data){
    const slideItem = useRef(null);
    const [openChk,setOpenChk] = useState(false);

    const [slideChk,setSlideChk] = useState(true);

    useEffect(()=>{
        if(data.id !== data.closeChk){
            slideUp(slideItem.current,300)
            setOpenChk(false)
        }
    },[data.closeChk])

    return (
        <div className={`accordionBox ${openChk ? "active" : ""}`}>
            <button type="button" onClick={(e)=>{
                if(slideChk){
                    setSlideChk(false);
                    data.func(data.id);
                    setTimeout(function(){
                        slideToggle(slideItem.current,"flex")
                        setOpenChk(!openChk)
                    },0);
                    setTimeout(() => {
                        setSlideChk(true);
                    }, 300);
                }
            }} className="accordionTitleBox accordionItem">
                <div className="accordionTextBox">
                    {data.questionIcon ? <span className="accordionIcon questionIcon">Q.</span> : ""}
                    <h1 className="accordionTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
                </div>
                <img src="/assets/images/basic/accordion_icon.svg"/>
            </button>
            <div className="answerText accordionItem" ref={slideItem}>
                {data.answerIcon ? <h1 className="accordionIcon answerIcon">A.</h1> : ""}
                <div className="accordionTextBox">
                    <p className="accordionText" dangerouslySetInnerHTML={{__html:data.text}}/>
                </div>
            </div>
        </div>
    );
}

export {AccordionSection, AccordionItem};