import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { InputItemBox } from "component/basic/formItems";
import { LoadingBox, Popup, SelPopup } from "component/basic/popup";
import { Header, } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { ContentsSection, InputSection, PageSizing, PageTitle } from "component/app/items";
import useGet from "api/useGet";

const PhoneEdit = (props) => {
    const navigate = useNavigate();

    const [page,setPage] =  useState(0);
    const [popupData,setPopupData] =  useState(null);
    const [selPopupData,setSelPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    
    const [btnChk,setBtnChk] =  useState(true);

    const [cetified,setCetified] =  useState("");
    const [certifyErr,setCertifyErr] =  useState(null);
    const [timerCount,setTimerCount] =  useState("3:00");
    const [cetifiedChk,setCetifiedChk] =  useState(false);
    const [cetifiedComChk,setCetifiedComChk] =  useState(false);

    const [responseSeq,setResponseSeq] =  useState("");

    const [name,setName] =  useState("");
    const [birthday,setBirthday] =  useState("");
    const [phone,setPhone] =  useState("");
    const [phoneType,setPhoneType] =  useState("");
    
    const [submitChk,setSubmitChk] =  useState(true);

    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("3:00");
        setCertifyErr("");
        
        if (sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }
        
        timerChk(2.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;
                    setCertifyErr("인증시간이 초과되었습니다.");
                    setCetifiedComChk(true);
                    setCetified("");

                    setBtnChk(false);
                }
            }, 1000);
        }
    }

    function nextFunc(){
        setBtnChk(true);                    
        setPage(page+1);
    }

    function cetifiedStert(){
        if(submitChk){
            setSubmitChk(false);
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("name", name);
            formData.append("birth", birthday.replace(/\-/g,""));
            formData.append("phone", phone);
            formData.append("mobile_carrier", phoneType.val);
            formData.append("is_join", 0);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/certification`,
                success: (data) => {
                    timerStart();
                    setCetified("");
                    setCetifiedChk(true);
                    setLoadingBoxOpen(false);

                    setResponseSeq(data?.data?.response_seq);
            
                    nextFunc();

                    setSubmitChk(true);
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    function pageNext(){
        if(page == 2){
            cetifiedStert();
        }else if(page == 3){
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("name", name);
            formData.append("birth", birthday.replace(/\-/g,""));
            formData.append("phone", phone);
            formData.append("mobile_carrier", phoneType.val);

            formData.append("response_seq", responseSeq);
            formData.append("certification_code", cetified);
            formData.append("is_join", 0);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/certification/check`,
                success: (data) => {
                    setBtnChk(false);
                    setCetifiedComChk(true);

                    setTimerCount("3:00");
                    setCertifyErr("");

                    clearInterval(sval.current);
                    sval.current = null;

                    setLoadingBoxOpen(false);

                    setPopupData({
                        addClass:null,
                        title:"휴대폰 번호 변경 완료",
                        text:"휴대폰 번호 변경이 완료되었습니다!",
                        closeType:false,
                        closeFunc:(e) => {},
                        btn0:"확인",
                        btn0Type:"",
                        btnFunc0:(e)=>{navigate(-1)},
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    if(data.alert){
                        setCertifyErr(data.alert);
                    }
                    setLoadingBoxOpen(false);
                }
            })
        }else{
            nextFunc();
        }
    }

    useEffect(() => {
        let ragChk = /\d{2}([0]\d|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])[-]*[1-4]/g;
        let phoneChk = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

        if(page == 0){
            if(name.length > 0){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(page == 1){
            if(name.length > 0 
            && ragChk.test(birthday.replace(/\-/g,""))){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(page == 2){
            if(name.length > 0 
            && ragChk.test(birthday.replace(/\-/g,""))
            && phoneChk.test(phone)
            && phoneType.val){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(page == 3){
            if(name.length > 0 
            && ragChk.test(birthday.replace(/\-/g,""))
            && phoneChk.test(phone)
            && phoneType.val
            && cetified.length == 6){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }
    }, [name,birthday,phone,phoneType,cetified]);

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle={""}
            />
            <ContentsSection addClass="headerContents btnContents">
                <PageTitle
                    contents={page == 0 ? "이름을<br/>입력해 주세요."
                    : page == 1 ? "주민등록번호 앞 6자리와<br/>뒤 1자리를 입력해 주세요."
                    : page == 2 ? "휴대폰 번호를<br/>입력해 주세요."
                    : page == 3 ? "인증번호를<br/>입력해 주세요."
                    : ""}
                    subTitle={"정보 변경을 위해 휴대폰 인증이 필요해요."}
                    subTitleBtn={null}
                    subTitleBtnFunc={()=>{}}
                />
                <InputSection>
                    {
                        page <= 3 && cetifiedChk ? 
                            <InputItemBox
                                inputType={null}
                                addClass="cetifiedInput"
                                inputName="인증 번호"
                                placeholder="인증 번호"
                                value={cetified}
                                max={6}
                                readOnly={cetifiedComChk}
                                cetified={timerCount}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setCetified(value)}}
                                errMsg={certifyErr}
                                subBtn="인증번호 재발송"
                                inputSubFunc={()=>{cetifiedStert();setCetifiedComChk(false);setCetified("")}}
                            />
                        :
                        ""
                    }
                    {
                        page >= 2 && page <= 3 ? 
                            <>
                                <InputItemBox
                                    type={null}
                                    addClass=""
                                    inputName="통신사"
                                    placeholder="통신사"
                                    value={phoneType.text}
                                    readOnly={true}
                                    func={(e) => {}}
                                    selItem={true}
                                    clickInput={(e) => setSelPopupData({
                                        title:"통신사 선택",
                                        addClass:null,
                                        closeType:true,
                                        items:["SKT","KT","LGU+","알뜰폰(SKT)","알뜰폰(KT)","알뜰폰(LGU+)"],
                                        val:[1,2,3,4,5,6],
                                        sel:phoneType.val,
                                        closeFunc:(e) => {},
                                        func:(e) => {
                                            setPhoneType({
                                                text:e.text,
                                                val:e.val
                                            });
                                            setCetifiedChk(false);setCetifiedComChk(false);setCetified("")
                                        }
                                    })}
                                />
                                <InputItemBox
                                    inputType={null}
                                    addClass=""
                                    inputName="휴대폰 번호"
                                    placeholder="‘-’ 없이 입력"
                                    value={phone}
                                    max={11}
                                    regexp={"number"}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    func={(value)=>{setPhone(value);setCetifiedChk(false);setCetifiedComChk(false);setCetified("");setPage(2)}}
                                />
                            </>
                        :
                        ""
                    }
                    {
                        page >= 1 && page <= 3 ? 
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="주민등록번호 앞 6자리 + 뒤 1자리"
                                placeholder="주민등록번호 앞 6자리 + 뒤 1자리"
                                birthSetting={true}
                                value={birthday}
                                regexp={/[^0123456789\-]/gi}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setBirthday(value)}}
                            />
                        :
                        ""
                    }
                    {
                        page >= 0 && page <= 3 ? 
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="이름"
                            placeholder="이름"
                            value={name}
                            max={10}
                            regexp={null}
                            func={(value)=>{setName(value)}}
                        />
                        :
                        ""
                    }
                </InputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={page == 3 ? "인증 완료" : "다음으로"}
                        disabled={btnChk}
                        func={() => {pageNext()}}
                    />
                </BtnBox>
            </ContentsSection>
            <SelPopup
                data={selPopupData}
            />
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default PhoneEdit;