import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { InputItemBox } from "component/basic/formItems";
import { LoadingBox, Popup } from "component/basic/popup";
import { Header, } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import { ContentsSection, InputSection, PageSizing, PageTitle } from "component/app/items";
import useGet from "api/useGet";

const FindPw = (props) => {
    const navigate = useNavigate();

    const [page,setPage] =  useState(0);
    const [popupData,setPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    
    const [btnChk,setBtnChk] =  useState(true);

    const [cetified,setCetified] =  useState("");
    const [certifyErr,setCertifyErr] =  useState(null);
    const [timerCount,setTimerCount] =  useState("3:00");
    const [cetifiedChk,setCetifiedChk] =  useState(false);
    const [cetifiedComChk,setCetifiedComChk] =  useState(false);

    const [phone,setPhone] =  useState("");

    const [id,setId] =  useState("");
    const [pw,setPw] =  useState("");
    const [pwCom,setPwCom] =  useState("");

    const [pwErr,setPwErr] =  useState(null);
    const [pwComErr,setPwComErr] =  useState(null);

    const [submitChk,setSubmitChk] =  useState(true);
    
    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("3:00");
        setCertifyErr("");
        
        if (sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }
        
        timerChk(2.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;
                    setCertifyErr("인증시간이 초과되었습니다.");
                    setCetifiedComChk(true);
                    setCetified("");

                    setBtnChk(false);
                }
            }, 1000);
        }
    }

    function pwCh(){
        if(submitChk){
            setSubmitChk(false);
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("email", id);
            formData.append("phone", phone);
            formData.append("code", cetified);
            formData.append("password", pw);
            formData.append("password_check", pwCom);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/change/password`,
                success: (data) => {
                    setSubmitChk(true);
                    setLoadingBoxOpen(false);
                    setPopupData({
                        addClass:null,
                        title:"새 비밀번호 설정 완료",
                        text:"새로운 비밀번호가 설정되었습니다.<br/>지금 바로 로그인해 보세요!",
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{navigate("/member/login")},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                    setBtnChk(false);
                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    function idChk(){
        if(submitChk){
            setSubmitChk(false);
            let formData = new FormData();
            formData.append("email", id);
            formData.append("phone", phone);
            formData.append("code", cetified);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/password/check`,
                success: (data) => {
                    setLoadingBoxOpen(false);
                    setSubmitChk(true);
                    findNextFunc();
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                    setBtnChk(false);
                    setLoadingBoxOpen(false);
                    setCetifiedChk(false);setCetifiedComChk(false);setCetified("");setPage(1);
                }
            })
        }
    }

    function findNextFunc(){
        setBtnChk(true);
        setPwErr(null)
        setPwComErr(null)
                    
        setPage(page+1);
    }

    function cetifiedStert(type){
        setLoadingBoxOpen(true);

        let formData = new FormData();
        formData.append("phone", phone);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/sms/certification`,
            success: (data) => {
                timerStart();
                setCetified("");
                setCetifiedChk(true);
                setLoadingBoxOpen(false);

                setBtnChk(true);
                if(type !== "re"){
                    setPage(page+1);
                }
            },
            err: (data) => {
                if(data.data || data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.alert||"",
                        text:data.data||"",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
                setLoadingBoxOpen(false);
            }
        })
    }

    function findNext(){
        if(page == 1){
            cetifiedStert();
        }else if(page == 2){
            setBtnChk(true);
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("phone", phone);
            formData.append("code", cetified);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/sms/certification/check`,
                success: (data) => {
                    setCetifiedComChk(true);

                    setLoadingBoxOpen(false);

                    setTimerCount("3:00");
                    setCertifyErr("");

                    clearInterval(sval.current);
                    sval.current = null;

                    idChk();
                },
                err: (data) => {
                    if(data.alert){
                        setCertifyErr(data.alert);
                    }
                    setLoadingBoxOpen(false);
                    setBtnChk(false);
                }
            })
        }else if(page == 4){
            setBtnChk(true);
            pwCh();
        }else{
            findNextFunc();
        }
    }

    //뒤로가기(물리버튼)
    function backEventPopup(){
        setPopupData({
            addClass:null,
            title:"비밀번호 찾기 취소",
            text:"비밀번호 찾기를 취소하고<br/>로그인 화면으로 돌아갈까요?",
            closeType:true,
            closeFunc:(e) => {},
            btn0:"취소",
            btn0Type:"line",
            btnFunc0:(e)=>{},
            btn1:"확인",
            btn1Type:"",
            btnFunc1:()=>{navigate(-2)}
        });
    }

    window.backEvent = () => {
        backEventPopup();
    }

    useEffect(() => {
        let phoneChk = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
        let idType = /^(?=.*[a-zA-Z0-9]).{4,12}$/;
        let pwType = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/;

        if(pw !== pwCom && pw !== "" && pwCom !== ""){
            setPwComErr("비밀번호가 일치하지 않습니다.")
        }else{
            setPwComErr(null)
        }

        if(!pwType.test(pw) && pw !== ""){
            setPwErr("영문, 숫자, 특수문자 조합 8-20자로 설정해 주세요.")
        }else{
            setPwErr(null)
        }

        if(page == 0){
            if(idType.test(id) && id !== ""){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(page == 1){
            if(idType.test(id) && id !== ""
            && phoneChk.test(phone)){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(page == 2){
            if(idType.test(id) && id !== ""
            && phoneChk.test(phone)
            && cetified.length == 6){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(page == 3){
            if(pwType.test(pw) && pw !== ""){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(page == 4){
            if(pwType.test(pw) && pw !== ""
            && pw == pwCom && pwType.test(pw)){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }
    }, [id,phone,cetified,pw,pwCom]);

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {backEventPopup()}}
                headTitle={""}
            />
            <ContentsSection addClass="headerContents btnContents">
                <PageTitle
                    contents={page == 3 ? "변경하실 비밀번호를<br/>입력해 주세요." : page == 4 ? "변경하실 비밀번호를 한 번 더<br/>입력해 주세요." : "비밀번호를 잊으셨나요?"}
                    subTitle={page == 0 ? "가입하신 아이디를 입력해 주세요."
                    : page == 1 ? "가입 시 입력한 휴대폰 번호를 알려주세요."
                    : page == 2 ? "인증번호를 입력해 주세요." : ""}
                />
                <InputSection>
                    {
                        page == 2 && cetifiedChk ? 
                            <InputItemBox
                                inputType={null}
                                addClass="cetifiedInput"
                                inputName="인증 번호"
                                placeholder="인증 번호"
                                value={cetified}
                                max={6}
                                readOnly={cetifiedComChk}
                                cetified={timerCount}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setCetified(value)}}
                                errMsg={certifyErr}
                                subBtn="인증번호 재발송"
                                inputSubFunc={()=>{cetifiedStert("re");setCetifiedComChk(false);setCetified("")}}
                            />
                        :
                        ""
                    }
                    {page >= 1 && page <= 2 ? 
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="휴대폰 번호"
                            placeholder="‘-’ 없이 입력"
                            value={phone}
                            max={11}
                            regexp={"number"}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            func={(value)=>{setPhone(value);setCetifiedChk(false);setCetifiedComChk(false);setCetified("");setPage(1)}}
                        />
                    :""}
                    {page >= 0 && page <= 2 ? 
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="아이디"
                            placeholder="아이디"
                            value={id}
                            max={12}
                            regexp={/[^0-9a-zA-Z]/gi}
                            func={(value)=>{setId(value)}}
                        />
                    :""}
                    {
                        page >= 3 && page <= 4 ? 
                        <InputItemBox
                            inputType="password"
                            addClass=""
                            inputName="새 비밀번호"
                            placeholder="새 비밀번호"
                            value={pw}
                            max={20}
                            regexp={null}
                            errMsg={pwErr}
                            func={(value)=>{setPw(value)}}
                            caption="영문 + 숫자 +특수조합 8~20자"
                        />
                        :
                        ""
                    }
                    {
                        page == 4 ? 
                        <InputItemBox
                            inputType="password"
                            addClass=""
                            inputName="새 비밀번호 확인"
                            placeholder="새 비밀번호 확인"
                            value={pwCom}
                            max={20}
                            regexp={null}
                            errMsg={pwComErr}
                            func={(value)=>{setPwCom(value)}}
                        />
                        :
                        ""
                    }
                </InputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={page == 2 ? "비밀번호 찾기" : page == 4 ? "설정 완료" : "다음으로"}
                        disabled={btnChk}
                        func={() => {findNext()}}
                    />
                </BtnBox>
            </ContentsSection>
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default FindPw;