import { useState } from "react";
import { useNavigate } from "react-router-dom";

//footer 메뉴
function FooterManu(data){
    const navigate = useNavigate();

    return (
        <div className="footerMenu">
            <button onClick={() => navigate("/")} className={`footerMenuItem ${data.menuChk == 0 ? "active" : ""}`}>
                <img src={`/assets/images/icon/${data.menuChk == 0 ? "footer_0_on.svg" : "footer_0_off.svg"}`}/>
                메인 홈
            </button>
            <button onClick={() => navigate("/package")} className={`footerMenuItem ${data.menuChk == 1 ? "active" : ""}`}>
                <img src={`/assets/images/icon/${data.menuChk == 1 ? "footer_1_on.svg" : "footer_1_off.svg"}`}/>
                서비스
            </button>
            <button onClick={() => navigate("/wash/ticket")} className={`footerMenuItem ${data.menuChk == 2 ? "active" : ""}`}>
                <img src={`/assets/images/icon/${data.menuChk == 2 ? "footer_2_on.svg" : "footer_2_off.svg"}`}/>
                이용권
            </button>
            <button onClick={() => navigate("/report/about")} className={`footerMenuItem ${data.menuChk == 3 ? "active" : ""}`}>
                <img src={`/assets/images/icon/${data.menuChk == 3 ? "footer_3_on.svg" : "footer_3_off.svg"}`}/>
                리포트
            </button>
            <button onClick={() => navigate("/mypage")} className={`footerMenuItem ${data.menuChk == 4 ? "active" : ""}`}>
                <img src={`/assets/images/icon/${data.menuChk == 4 ? "footer_4_on.svg" : "footer_4_off.svg"}`}/>
                내 정보
            </button>
        </div>
    );
}

//footer
function Footer(data){
    const [footerOpen,setFooterOpen] = useState(true);

    return (
        <div className={`footer ${data.addClass||""}`}>
            <div className={`footerSection ${footerOpen ? "active" : ""}`}>
                <button type="button" onClick={()=>{setFooterOpen(!footerOpen)}} className="footer_title">주식회사 레이노섹터1 <img src="/assets/images/basic/footer_open.svg"/></button>
                {footerOpen ?
                    <div className="footer_infoArea">
                        <p className="footer_infoText"><span>대표자</span>김현철</p>
                        <p className="footer_infoText"><span>사업자등록번호</span>355-81-02192</p>
                        <p className="footer_infoText"><span>주소</span>경기도 성남시 분당구 대왕판교로 241 (궁내동)</p>
                        <p className="footer_infoText"><span>고객센터</span>0507-1365-8695</p>
                        <p className="footer_infoText"><span>이메일</span>autonova.official@gmail.com</p>
                        <p className="footer_infoText"><span>통신판매업신고</span>제2021-성남분당C-42호</p>
                        <p className="footer_infoText"><span>개인정보 보호책임자</span>김재원</p>
                    </div>
                :""}
                {data.termsType ? 
                    <div className="footer_termsBox">
                        <button type="button" onClick={()=>window.rightOpen(1,"서비스 이용약관")} className="footer_termsItem">이용약관</button>
                        <button type="button" onClick={()=>window.rightOpen(2,"개인정보처리방침")} className="footer_termsItem">개인정보처리방침</button>
                    </div>
                :""}
            </div>
        </div>
    );
}

export {FooterManu,Footer}