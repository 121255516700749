import ChannelService from "js/channelTalk";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ChannelTalkLoad(){
    useEffect(() => {
        if(!window.ChannelIO){
            ChannelService.loadScript();
            ChannelService.boot({
                pluginKey: process.env.REACT_APP_CHANNELTALK_ID,
            });
        }else{
            ChannelService.showChannelButton();
        }
    }, []);
  
    return null;
}

function ChannelTalkHide(){
    const { pathname } = useLocation();

    useEffect(() => {
        if(window.ChannelIO && (pathname !== "/faq" && pathname !== "/event" && pathname !== "/notice")){
            ChannelService.hideChannelButton();
            ChannelService.hideMessenger();
        }
    }, [pathname]);
  
    return null;
}

export {ChannelTalkLoad,ChannelTalkHide};