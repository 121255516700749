import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { CustomSelect, InputItemBox } from "component/basic/formItems";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, InfoTextBox, ItemSection, PageSizing } from "component/app/items";
import { Popup } from "component/basic/popup";
import bankData from "../../data/data.json";
import { DetailItem } from "component/detailing/item";
import { getDateGap } from "js/function";

const DetailingTicketCancel = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const [popupData,setPopupData] =  useState(null);

    const detailingApi = useGet({
        url:`/detailing/detail?detailing_reservation_id=${id}`,
        loginType:"login"
    });
    const detailingData = detailingApi?.data?.detailing_reservation;

    const [bankCode,setBankCode] =  useState({});
    const [bankAccount,setBankAccount] =  useState("");
    const [bankHolder,setBankHolder] =  useState("");

    const [chkDate,setChkDate] =  useState(true);

    const [btnChk,setBtnChk] =  useState(true);

    const [submitChk,setSubmitChk] =  useState(true);
    
    function cancelSubmit(){
        if(submitChk){
            setSubmitChk(false);

            let formData = new FormData();
            formData.append("detailing_reservation_id", id);

            if(chkDate){
                formData.append("bank_code", bankCode?.code);
                formData.append("bank_account", bankAccount);
                formData.append("bank_holder", bankHolder);
            }

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/detailing/reservation/cancel`,
                success: (data) => {
                    setPopupData({
                        addClass:null,
                        title:"구매취소 신청 완료",
                        text:"환불 신청이 완료되었습니다.<br/>3~7영업일 이내 처리될 예정입니다.",
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{navigate(-1)},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                }
            })
        }
    }

    function btnChkFunc(){
        if(bankCode?.code && bankAccount !== "" && bankHolder !== ""){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }

    useEffect(() => {
        if(detailingData){
            if(detailingData?.status !== 1){
                navigate("/pageErr")
            }

            setChkDate(getDateGap(detailingData?.payment_date,String(new Date()),"day",false) > 30);

            if(getDateGap(detailingData?.payment_date,String(new Date()),"day",false) > 30){
                btnChkFunc();
            }else{
                setBtnChk(false);
            }
        }
    }, [detailingApi]);

    useEffect(() => {
        btnChkFunc();
    }, [bankCode,bankAccount,bankHolder]);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="구매취소 신청"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <ItemSection>
                    <DetailItem
                        addClass={detailingData?.status !== 1 ? "isUseType" : ""}
                        id={detailingData?.id}
                        name={detailingData?.name||""}
                        date={detailingData ? `<span class='${detailingData?.status == 1 ? "gTextColor" : ""}'>예약일시 : ${detailingData?.date} ${detailingData?.time}</span>` : ""}
                        caption={detailingData?.contents||""}
                        textBtn={null}
                        textBtnFunc={()=>{}}
                        btnText={detailingData?.status == 1 ? "사용가능" : detailingData?.status == 2 ? "사용완료" : detailingData?.status == 3 ? "취소요청" : detailingData?.status == 4 ? "취소완료" : detailingData?.status == 10 ? "사용불가" : ""}
                        btnFunc={()=>{}}
                        textType={true}
                    />
                    {chkDate ?
                    <>
                    <CustomSelect
                        addClass=""
                        inputName="은행명"
                        placeholder="은행명"
                        value={bankCode?.code}
                        func={(name,val) => {
                            let setData = {
                                "code":val,
                                "name":name
                            }
                            setBankCode(setData);
                        }}
                        disabled={false}
                        valKey="code"
                        nameKey="name"
                        options={bankData?.bankCades}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="계좌번호"
                        placeholder="계좌번호"
                        value={bankAccount}
                        max={null}
                        regexp={"number"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{setBankAccount(value)}}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="예금주"
                        placeholder="예금주"
                        value={bankHolder}
                        max={null}
                        regexp={null}
                        func={(value)=>{setBankHolder(value)}}
                    />
                    </>
                    :""}
                </ItemSection>
                <InfoTextBox
                    title="구매취소 안내"
                    text="&#183; 사용하지 않은 이용권은 구매일로부터 7일 안에 환불 및 취소하실 수 있습니다.<br/>
                        &#183; 사용하신 이용권은 환불받으실 수 없습니다.<br/>
                        &#183; 이용권의 유효 기간은 연장하실 수 없습니다.<br/>
                        &#183; 환불 소요 기간은 카드사 및 은행 사정에 따라서 일정에 차이가 있을 수 있습니다. "
                />
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"구매취소 신청"}
                        disabled={btnChk}
                        func={() => {
                            cancelSubmit()
                        }}
                    />
                </BtnBox>
            </ContentsSection>
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default DetailingTicketCancel;