import { ContentsSection, InputSection, LogoBox, PageSizing } from "component/app/items";
import * as fatchSet from "../../api/api";
import { BtnItem, TextBtnItem } from "component/basic/btns";
import { InputItemBox } from "component/basic/formItems";
import { Popup } from "component/basic/popup";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoginSubLink } from "component/member/item";

const Login = (props) => {
    const navigate = useNavigate();

    const [id,setId] =  useState("");
    const [pw,setPw] =  useState("");

    const [popupData,setPopupData] =  useState(null);
    const [btnChk,setBtnChk] =  useState(true);

    function loginStart(){
        let formData = new FormData();
        formData.append("email", id);
        formData.append("password", pw);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/login",
            success: (data) => {
                localStorage.setItem("token",data.data.token.access_token);
                localStorage.setItem("refresh_token",data.data.token.refresh_token);

                localStorage.setItem("carSize",data.data.carSize);

                window.logIn();

                setTimeout(() => {
                    window.joinChkApi();
                    
                    if(sessionStorage.getItem("setUrl")){
                        let reUrl = sessionStorage.getItem("setUrl");
                        sessionStorage.removeItem("setUrl");
                        navigate(reUrl);
                    }else{
                        navigate(`/`);
                    }
                }, 100);
            },
            err: (data) => {
                if(data.data || data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.alert||"",
                        text:data.data||"",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    useEffect(() => {
        let idType = /^(?=.*[a-zA-Z0-9]).{4,12}$/;
        let pwType = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/;

        if(pwType.test(pw) && pw !== "" && idType.test(id) && id !== ""){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [id,pw]);

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle={null}
            />
            <ContentsSection addClass="headerContents bottomContents">
                <div className="loginContetns">
                    <LogoBox/>
                    <InputSection>
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="아이디"
                            placeholder="아이디"
                            value={id}
                            max={12}
                            regexp={/[^0-9a-zA-Z]/gi}
                            func={(value)=>{setId(value)}}
                        />
                        <InputItemBox
                            inputType="password"
                            addClass=""
                            inputName="비밀번호"
                            placeholder="비밀번호 입력"
                            pwChType={true}
                            value={pw}
                            max={20}
                            regexp={null}
                            func={(value)=>{setPw(value)}}
                        />
                        <BtnItem
                            addClass="subType inputInBtn"
                            contents="로그인"
                            disabled={btnChk}
                            func={() => {loginStart()}}
                        />
                        <div className="loginSubLinkBox">
                            <LoginSubLink func={()=>{navigate("/member/terms/email")}} contents="회원가입"/>
                            <span></span>
                            <LoginSubLink func={()=>{navigate("/member/find/id")}} contents="아이디 찾기"/>
                            <span></span>
                            <LoginSubLink func={()=>{navigate("/member/find/pw")}} contents="비밀번호 찾기"/>
                        </div>
                    </InputSection>
                </div>
                <div className="loginContetns subLogin_linkBox">
                    <TextBtnItem contents="다른 방법으로 로그인" func={() => {navigate(-1)}}/>
                </div>
            </ContentsSection>
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default Login;