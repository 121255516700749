import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { CustomSelect, InputItemBox } from "component/basic/formItems";
import { LoadingBox, Popup, SelPopup, ToastPopup } from "component/basic/popup";
import { Header, } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, InfoTextBox, InputSection, PageSizing, PageTitle } from "component/app/items";
import { CarInfoPopupContents, CarNumber } from "component/member/item";
import useGet from "api/useGet";

const CarEdit = (props) => {
    const navigate = useNavigate();

    const [page,setPage] =  useState(0);
    const [popupData,setPopupData] =  useState(null);
    const [selPopupData,setSelPopupData] =  useState(null);
    const [toastPopupData,setToastPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    
    const [btnChk,setBtnChk] =  useState(true);

    const [carNumber,setCarNumber] =  useState("");
    const [carMaker,setCarMaker] =  useState({});
    const [carName,setCarName] =  useState("");
    const [carCategory,setCarCategory] =  useState("");
    const [carOwner,setCarOwner] =  useState("");

    const [carImg,setCarImg] =  useState("");

    const makerApi = useGet({
        url:page == 1 ? `/car/maker` : null,
        loginType:"login"
    });
    const makerData = makerApi?.data?.car_makers||[];

    const carCategoryData = [
        {id:"세단"},
        {id:"SUV"},
        {id:"밴/트럭/MPV"}
    ];
    
    function nextFunc(){
        setBtnChk(true);
        setPage(page+1);
    }

    function carRegister(){
        setLoadingBoxOpen(true);
        
        let formData = new FormData();

        formData.append("is_direct", page == 0 ? 0 : 1);
        formData.append("car_number", carNumber);
        formData.append("car_owner", carOwner);

        if(page == 1){
            formData.append("car_maker", carMaker?.name);
            formData.append("car_name", carName);
            formData.append("car_category", carCategory);
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType:"login",
            url: `/car/register`,
            success: (data) => {
                setLoadingBoxOpen(false);

                setToastPopupData(null)
                setPopupData({
                    addClass:null,
                    title:"차량 정보 변경 완료",
                    text:"차량 정보 변경이 완료되었습니다.",
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{navigate(-1)},
                    btn0:"확인",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });
            },
            err: (data) => {
                if(data.data || data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.alert||"",
                        text:data.data||"",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
                setLoadingBoxOpen(false);
            }
        })
    }

    function pageNext(){
        if(page == 0){
            setLoadingBoxOpen(true);

            let formData = new FormData();

            formData.append("car_number", carNumber);
            formData.append("car_owner", carOwner);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/car/info`,
                success: (data) => {
                    setLoadingBoxOpen(false);

                    let makerData = {
                        id:"",
                        name:data?.data?.maker
                    }             
                    setCarMaker(makerData);
                    setCarName(data?.data?.name);
                    setCarCategory(data?.data?.category);
                    setCarImg(data?.data?.car_img);

                    setToastPopupData({
                        addClass:null,
                        title:"차량 정보 확인",
                        closeType:true,
                        closeFuncChk:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{},
                        textBtn:"직접 입력",
                        textBtnFunc:()=>{
                            setBtnChk(true);
                
                            setCarNumber("");
                            setCarMaker({});
                            setCarName("");
                            setCarCategory("");
                            setCarOwner("");
                                        
                            setPage(1);
                        }
                    });
                },
                err: (data) => {
                    if(data.alert == "일 차량조회 횟수 초과"){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"취소",
                            btn0Type:"",
                            btn1:"직접 입력",
                            btn1Type:"",
                            btnFunc1:()=>{
                                setBtnChk(true);

                                setCarNumber("");
                                setCarMaker({});
                                setCarName("");
                                setCarCategory("");
                                setCarOwner("");
                                            
                                setPage(1);
                            }
                        });
                    }else if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                    setLoadingBoxOpen(false);
                }
            })
        }else if(page == 1){
            carRegister();
        }else{
            nextFunc();
        }
    }

    useEffect(() => {
        let carChk = /\d{2,3}[가-힣]{1}\d{4}/gm;

        if(page == 0){
            if(carChk.test(carNumber) && carOwner !== ""){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(page == 1){
            if(carChk.test(carNumber)
            && carMaker?.id
            && carName !== ""
            && carCategory !== ""
            && carOwner !== ""){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }
    }, [carNumber,carOwner,carMaker,carName,carCategory]);
    

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle={""}
            />
            <ContentsSection addClass="headerContents btnContents">
                <PageTitle
                    contents={"고객님의 차량번호를<br/>입력해 주세요."}
                    subTitle={""}
                    subTitleBtn={page == 0 ? "직접 입력하시겠어요?" : null}
                    subTitleBtnFunc={()=>{
                        if(page == 0){
                            setBtnChk(true);

                            setCarNumber("");
                            setCarMaker({});
                            setCarName("");
                            setCarCategory("");
                            setCarOwner("");
                                        
                            setPage(1);
                        }
                    }}
                />
                <InputSection>
                    {
                        page == 0 ? 
                        <>
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="차량번호"
                                placeholder="차량번호"
                                value={carNumber}
                                max={8}
                                regexp={null}
                                func={(value)=>{setCarNumber(value)}}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="차량 소유주"
                                placeholder="차량 소유주"
                                value={carOwner}
                                max={null}
                                regexp={null}
                                func={(value)=>{setCarOwner(value)}}
                            />
                            <InfoTextBox
                                title="차량정보 입력 안내"
                                text='&#183; 개인 소유 차량은 실제 소유주 성함을 입력해주세요.<br/>
                                &#183; 법인/리스/렌탈 차량은 회사명을 입력해주세요.<br/>
                                <span class="infoTextLineChk"></span> 입력 시 <b>(주), 주식회사</b> 등을 포함해 정확히 입력해주세요.<br/>
                                &#183; 개인 소유 차량 소유주 입력 예시 : "<b>홍길동</b>"<br/>
                                &#183; 법인/리스/렌탈 차량 소유주 입력 예시 : "<b>메리츠캐피탈(주)</b>"'
                            />
                        </>
                        :
                        ""
                    }
                    {
                        page == 1 ? 
                        <>
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="차량번호"
                                placeholder="차량번호"
                                value={carNumber}
                                max={8}
                                regexp={null}
                                func={(value)=>{setCarNumber(value)}}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="차량 소유주"
                                placeholder="차량 소유주"
                                value={carOwner}
                                max={null}
                                regexp={null}
                                func={(value)=>{setCarOwner(value)}}
                            />
                            <CustomSelect
                                inputName="차량 제조사"
                                placeholder="차량 제조사"
                                value={carMaker?.id}
                                func={(name,val) => {
                                    let dataSet = {
                                        id:val,
                                        name:name
                                    }
                                    setCarMaker(dataSet);
                                }}
                                disabled={false}
                                valKey="id"
                                nameKey="name"
                                options={makerData}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="차량 모델"
                                placeholder="차량 모델"
                                value={carName}
                                max={null}
                                regexp={null}
                                func={(value)=>{setCarName(value)}}
                            />
                            <CustomSelect
                                inputName="차량 외형"
                                placeholder="차량 외형"
                                value={carCategory}
                                func={(name,val) => {
                                    setCarCategory(val);
                                }}
                                disabled={false}
                                valKey="id"
                                nameKey="id"
                                options={carCategoryData}
                            />
                            <InfoTextBox
                                title="차량정보 입력 안내"
                                text='&#183; 개인 소유 차량은 실제 소유주 성함을 입력해주세요.<br/>
                                &#183; 법인/리스/렌탈 차량은 회사명을 입력해주세요.<br/>
                                <span class="infoTextLineChk"></span> 입력 시 <b>(주), 주식회사</b> 등을 포함해 정확히 입력해주세요.<br/>
                                &#183; 개인 소유 차량 소유주 입력 예시 : "<b>홍길동</b>"<br/>
                                &#183; 법인/리스/렌탈 차량 소유주 입력 예시 : "<b>메리츠캐피탈(주)</b>"'
                            />
                        </>
                        :
                        ""
                    }
                </InputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={page == 1 ? "입력 완료" : "다음으로"}
                        disabled={btnChk}
                        func={() => {pageNext()}}
                    />
                </BtnBox>
            </ContentsSection>
            <SelPopup
                data={selPopupData}
            />
            <ToastPopup
                data={toastPopupData}
            >
                <CarInfoPopupContents
                    carImg={carImg}
                    maker={carMaker?.name}
                    name={carName}
                    category={carCategory}
                />
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"네, 맞아요."}
                        disabled={false}
                        func={() => {carRegister()}}
                    />
                </BtnBox>
            </ToastPopup>
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default CarEdit;