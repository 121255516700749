import React from "react";
import { useEffect } from "react";

const Appstore = (props) => {
    useEffect(() => {
        var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

        if (varUA.indexOf("applewebkit") > -1 || varUA.indexOf("mac") > -1 || varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 ||varUA.indexOf("ipod") > -1) {
            window.location.href = 'https://apps.apple.com/kr/app/id6502380484' // TODO: 수정 필요
        } else if ( varUA.indexOf('android') > -1) {
            window.location.href = 'https://play.google.com/store/apps/details?id=kr.co.autonova' // TODO: 수정 필요
        } else {
            window.location.href = 'https://autonova.co.kr'
        }
    }, []);

    return (<></>)
};

export default Appstore;