import { useNavigate } from "react-router-dom";

function AlarmItem(data){
    let navigate = useNavigate();

    return (
        <div onClick={()=>{
            if(data.url){
                window.location.href = data.url;
            }
        }} className={`alarmItem ${data.addClass ? data.addClass : ""}`} style={data.url ? {cursor:"pointer"} : {}}>
            <img src="/assets/images/icon/alarm_list_icon.svg"/>
            <div className="alarmInfo">
                <h1 className="alarmInfo_title" dangerouslySetInnerHTML={{__html:data.title}}/>
                <p className="alarmInfo_text" dangerouslySetInnerHTML={{__html:data.contents}}/>
                <p className="alarmInfo_date" dangerouslySetInnerHTML={{__html:data.created_at}}/>
            </div>
        </div>
    );
}

export {AlarmItem};