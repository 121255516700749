import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, PageSizing } from "component/app/items";
import { Popup } from "component/basic/popup";
import { CardItem, CardItemBox } from "component/mypage/card";

const Card = (props) => {
    const navigate = useNavigate();

    const cardApi = useGet({
        url:"/card",
        loginType:"login"
    });
    const cardData = cardApi?.data?.user_card;

    const [popupData,setPopupData] =  useState(null);
    const [submitChk,setSubmitChk] =  useState(true);

    function cardDelOpen(){
        setPopupData({
            addClass:null,
            title:"카드 삭제하기",
            text:"등록하신 카드를 삭제하시겠습니까?",
            closeType:true,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{},
            btn0:"취소",
            btn0Type:"",
            btn1:"카드 삭제",
            btn1Type:"",
            btnFunc1:()=>{
                cardDel();
            }
        });
    }

    function cardDel(){
        if(submitChk){
            setSubmitChk(false);
            fatchSet.FatchApi({
                type:"DELETE",
                loginType:"login",
                url: `/card/delete?user_card_id=${cardData?.id}`,
                success: (data) => {
                    setSubmitChk(true);
                    setPopupData({
                        addClass:null,
                        title:"카드 삭제 완료",
                        text:"카드 삭제가 완료되었습니다.",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                }
            })
        }
    }

    useEffect(() => {
        
    }, []);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="결제 카드 관리"
                centerTitle={true}
                homeBtn={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <CardItemBox>
                    <CardItem
                        notCard={cardData ? false : true}
                        name={cardData?.name}
                        code={cardData?.card_code}
                        cardDelFunc={()=>{cardDelOpen()}}
                        cardNumber={cardData?.card_number}
                    />
                </CardItemBox>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={cardData ? `카드 변경하기` : `카드 등록`}
                        disabled={false}
                        func={() => {navigate("/mypage/card/add")}}
                    />
                </BtnBox>
            </ContentsSection>
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default Card;