import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, PageSizing } from "component/app/items";
import { Popup } from "component/basic/popup";
import { SettingItem } from "component/mypage/setting";

const Setting = (props) => {
    const navigate = useNavigate();

    const [update,setUpdate] =  useState(0);
    const [popupData,setPopupData] =  useState(null);

    const settingApi = useGet({
        url:`/setting?update=${update}`,
        loginType:"login"
    });
    const settingData = settingApi?.data;

    function settingSubmit(type){
        let formData = new FormData();
        formData.append("setting_type", type);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType:"login",
            url: `/setting/modify`,
            success: (data) => {
                setUpdate(update + 1);
            },
            err: (data) => {
                if(data.data || data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.alert||"",
                        text:data.data||"",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    useEffect(() => {
        
    }, []);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="설정"
                centerTitle={true}
                homeBtn={true}
            />
            <ContentsSection addClass="headerContents">
                <SettingItem
                    addClass=""
                    cursorType={"auto"}
                    func={()=>{}}
                    disabled={false}
                    name="서비스 알림"
                    nameCaption="서비스 이용 상태, 결제 등 알림"

                    subItemTextAddClass={null}
                    subItemText={null}
                    icon={null}

                    toggleBtn={true}
                    checkedType={settingData?.service_alarm == 1 ? true : false}
                    chkFunc={(e)=>{settingSubmit("service_alarm")}}
                    toggleId="service_alarm"
                />
                <SettingItem
                    addClass=""
                    cursorType={"auto"}
                    func={()=>{}}
                    disabled={false}
                    name="이벤트 소식 알림"
                    nameCaption="오토노바 소식과 이벤트 알림"

                    subItemTextAddClass={null}
                    subItemText={null}
                    icon={null}

                    toggleBtn={true}
                    checkedType={settingData?.event_alarm == 1 ? true : false}
                    chkFunc={(e)=>{settingSubmit("event_alarm")}}
                    toggleId="event_alarm"
                />
                <SettingItem
                    addClass=""
                    cursorType={null}
                    func={()=>{window.logOut();navigate("/")}}
                    disabled={false}
                    name="로그아웃"
                    nameCaption={null}

                    subItemTextAddClass={null}
                    subItemText={null}
                    icon="menu_move"

                    checkedType={null}
                    chkFunc={(e)=>{}}
                    toggleId={null}
                />
            </ContentsSection>
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default Setting;