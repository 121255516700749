import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { ChkBox } from "component/basic/formItems";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, EditerBox, InputSection, PageSizing, PageTitle } from "component/app/items";
import { RightPopup } from "component/basic/popup";

const Terms = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const type = param.type;

    const termsApi = useGet({
        url:"/term?type=join",
    });

    const termsData = termsApi?.data?.terms;

    const [checkItems, setCheckItems] = useState([]);
    const [checkItemTitles, setCheckItemTitles] = useState([]);
    const [btnChk,setBtnChk] =  useState(true);
    const [terms,setTerms] =  useState({});
    const [rightOpenType,setRightOpenType] =  useState(false);

    const termsDetailApi = useGet({
        url: terms?.id ? `/term/detail?term_id=${terms?.id}` : null,
    });
    const termsDetailData = termsDetailApi?.data?.term;
    
    // 체크박스 선택
    const handleSingleCheck = (checked, id, text) => {
         if (checked) {
            setCheckItemTitles(prev => [...prev, text]);
            setCheckItems(prev => [...prev, id]);
        } else {
            setCheckItemTitles(checkItemTitles.filter((el) => el !== text));
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {
        const idArr = [],
            textArr = [];
            termsData?.forEach((el) => {idArr.push(el.id);textArr.push(el.term_title)});
            setCheckItemTitles(textArr);
            setCheckItems(idArr);
        }
        else {
            setCheckItemTitles([]);
            setCheckItems([]);
        }
    }

    const rightOpen = (id,title) => {
        let termsItem = {
            id:id,
            title:title
        }
        setTerms(termsItem);
        setRightOpenType(true);
    }

    const joinStert = () => {    
        navigate(`/member/join/${type}`);
    }

    useEffect(() => {
        let chkSet = [...checkItems];
        if(chkSet.length == termsData?.length){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [checkItems]);

    useEffect(() => {
        if (type === 'email') {
            const search = window.location.search;

            if (search) {
                const queryString = search.substring(1); // '?' 문자 제거
                const pairs = queryString.split('&');

                pairs.forEach(pair => {
                    const [key, value] = pair.split('=');
                    if (key === 'recommend_id') {
                        localStorage.setItem("recommend_id", value);
                    }
                });
            }
        }
    }, [type]);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="약관 동의"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents bottomContents btnContents">
                <PageTitle
                    contents="회원가입을 진행하기 위해<br/>약관에 동의해 주세요."
                />
                <InputSection>
                    <ChkBox
                        addClass="allChkItem"
                        func={(e) => handleAllCheck(e.target.checked)}
                        checkedType={checkItems?.length === termsData?.length ? true : false}
                        // chkSubType=""
                        label="모든 약관에 동의합니다."

                    />
                    {termsData?.map((item, i) => (
                        <ChkBox
                            key={i}
                            addClass=""
                            func={(e) => handleSingleCheck(e.target.checked,item.id,item.title)}
                            checkedType={checkItems.includes(item.id) ? true : false}
                            label={`(필수) ${item.title}`}
                            chkFunc={(e) => rightOpen(item.id,item.title)}
                        />
                    ))}
                </InputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents="다음으로"
                        disabled={btnChk}
                        func={() => {joinStert()}}
                    />
                </BtnBox>
            </ContentsSection>
            <RightPopup
                topTitle={terms?.title}
                addClass={rightOpenType ? "active bottomBtn" : " bottomBtn"}
                closePopup={() => {setTerms({});setRightOpenType(false)}}
                openType={rightOpenType}
                bType={true}
            >
                <EditerBox addClass="termsText" data={termsDetailData?.contents}/>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents="확인하고 동의합니다."
                        disabled={false}
                        func={() => {
                            if(!checkItems.includes(terms?.id)){
                                handleSingleCheck(true,terms?.id,terms?.title);
                            };
                        setTerms({});setRightOpenType(false)}}
                    />
                </BtnBox>
            </RightPopup>
        </PageSizing>
    );
};

export default Terms;