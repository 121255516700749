import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, PageSizing } from "component/app/items";
import { NotItems } from "component/basic/notItems";
import { setDateTime } from "js/function";
import { AlarmItem } from "component/mypage/alarm";

const Alarm = (props) => {
    const navigate = useNavigate();

    const alarmApi = useGet({
        url:`/mypage/alarm`,
        loginType:"login"
    });
    const alarmData = alarmApi?.data?.alarms;

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="알림"
                centerTitle={true}
                homeBtn={true}
            />
            <ContentsSection addClass="headerContents">

                {alarmData?.length > 0 ?
                    <>
                        {alarmData && alarmData?.map((item, i) => (
                            <AlarmItem
                                key={i}
                                id={item.id}
                                addClass=""
                                title={item.title}
                                contents={item.contents}
                                url={item.url}
                                created_at={setDateTime(item.created_at,"yymmdd","hymd")}
                            />
                        ))}
                    </>
                :
                    <NotItems
                        addClass="fullPage"
                        img="/assets/images/icon/not_alarm.svg"
                        text={`수신된 알림이 없습니다.`}
                    />
                }
            </ContentsSection>
        </PageSizing>
    );
};

export default Alarm;