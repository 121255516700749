import { Popup } from "component/basic/popup";
import { bodyScrollDisabled } from "js/function";
import { useEffect, useState } from "react";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import { useNavigate } from "react-router-dom";

function PaymentBox(data){

    return (
        <div className="paymentBox">
            {data.children}
        </div>
    );
}

function PaymentItem(data){

    return (
        <button type="button" className={`paymentItem ${data.addClass||""} ${data.activeChk == data.id ? "active" : ""}`} disabled={data.disabled ? data.disabled : false} onClick={()=>{data.func(data.id)}}>
            <div className="paymentItem_iconBox">{data.icon ? <img src={data.icon}/> : ""}</div>
            <p className="paymentItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
        </button>
    );
}

function PaymentSuccess(data){
    let navigate = useNavigate();

    const [popupData,setPopupData] =  useState(null);
    const [addrPosition,setAddrPosition] =  useState({});
    const [tmapUrl,setTmapUrl] =  useState("/");

    //주소로 좌표 추출
    function positionData(addr){
        setTimeout(() => {
            if(addr){
                let geocoder = new window.kakao.maps.services.Geocoder();
                geocoder.addressSearch(addr, function(result, status) {
                    if (status === window.kakao.maps.services.Status.OK) {
                        let positionData = {
                            lat:result[0].y,
                            lng:result[0].x
                        }
                        setAddrPosition(positionData);
    
                        setTmapUrl(`https://apis.openapi.sk.com/tmap/app/routes?appKey=${process.env.REACT_APP_TMAP_KEY}&name=오토노바&lon=${result[0].x}&lat=${result[0].y}`);

                        setTimeout(() => {
                            popupDataUpdate(`https://apis.openapi.sk.com/tmap/app/routes?appKey=${process.env.REACT_APP_TMAP_KEY}&name=오토노바&lon=${result[0].x}&lat=${result[0].y}`);
                        }, 0);
                    } 
                });
            }
        }, 200);
    }

    function popupDataUpdate(url){
        setPopupData({
            addClass:null,
            title:data.title||"결제 완료",
            text:data.text||"결제가 완료되었습니다!<br/>지금 바로 오토노바 서비스를 이용하세요.",
            closeType:false,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{
                setPopupData(null);
                bodyScrollDisabled(false);
                
                setTimeout(() => {
                    data.openClose();
                    navigate("/");
                }, 10);
            },
            btn0:"확인",
            btn0Type:"",
            btn1:"길찾기",
            btn1Type:"link",
            btn1Link:url,
            notClose:true,
            btnFunc1:()=>{
                // data.openClose();
            }
        });
    }
    
    useEffect(() => {
        if(data.open){
            positionData(process.env.REACT_APP_ADDR);
        }
    }, []);

    useEffect(() => {
        if(data.open){
            popupDataUpdate(tmapUrl);
            
            setAddrPosition({});
            positionData(process.env.REACT_APP_ADDR);
        }
    }, [data.open]);

    return (
        <Popup
            data={popupData}
        >
            <div className="addrBox">
                {addrPosition?.lat && addrPosition?.lng ? 
                    <Map
                        center={{ lat: Number(addrPosition?.lat), lng: Number(addrPosition?.lng) }}
                        style={{ width: "100%", height: "220px" }}
                    >
                        <MapMarker position={{ lat: Number(addrPosition?.lat), lng: Number(addrPosition?.lng) }}/>
                    </Map>
                :""}
            </div>
        </Popup>
    );
}

function QrBox(data){

    return (
        <div className={`qrBox ${data.addClass||""}`}>
            {data.qrImg ? <img src={"data:image/png;base64, " + data.qrImg}/> : ""}
        </div>
    );
}

export {PaymentBox,PaymentItem,PaymentSuccess,QrBox};