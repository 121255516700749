import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, PageSizing } from "component/app/items";
import { NotItems } from "component/basic/notItems";
import { bottomChk } from "js/function";
import { ReportListItem } from "component/report/item";
import { Tap } from "component/basic/tap";
import { FooterManu } from "component/elements/footer";

const ReportList = (props) => {
    const navigate = useNavigate();

    const [page,setPage] =  useState(1);

    const [itemList,setItemList] =  useState([]);

    let lastPage = false;
    let settingChk = 0;

    const pageDataApi = useGet({
        url:`/detailing/report?page=${page}`,
        loginType:"login"
    });
    const pageData = pageDataApi?.data?.detailing_reports;

    function addPage(){
        setPage(page + 1);
        settingChk = 1;
        setTimeout(() => {
            settingChk = 0;
        }, 300);
    };

    useEffect(() => {
        if(pageData?.data && pageData?.data.length > 0){
            if(page == 1){
                setItemList([...pageData?.data]);
            }else{    
                setItemList(prev => [...prev, ...pageData?.data]);
            }
        }else{
            setItemList([]);
        }

        lastPage = pageData?.current_page < pageData?.last_page;

        window.removeEventListener("scroll", handleScroll);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [pageDataApi]);

    function handleScroll(){
        if(bottomChk() && lastPage && settingChk == 0){
            addPage();
        }
    };

    useEffect(() => {
        setItemList([]);
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={false}
                headTitle={null}
            >
                <Tap
                    addClass=""
                    func={(id)=>{
                        navigate(`/${id}`)
                    }}
                    val="report/list"
                    data={[
                        {id:"report/about",category:"소개"},
                        {id:"report/detail/new",category:"리포트"},
                        {id:"report/list",category:"내역"}
                    ]}
                />
            </Header>
            <ContentsSection addClass="headerContents footerContents">
                {itemList?.length > 0 ?
                    <>
                        {itemList && itemList?.map((item, i) => (
                            <ReportListItem
                                key={i}
                                addClass={""}
                                id={item?.id}
                                car_number={item?.car_number}
                                created_at={item?.created_at}
                                autolife_score={item?.autolife_score}
                            />
                        ))}
                    </>
                :
                    <NotItems
                        addClass="fullPage"
                        img="/assets/images/icon/not_report.svg"
                        text="리포트 내역이 없습니다."
                    />
                }
            </ContentsSection>
            <FooterManu menuChk="3"/>
        </PageSizing>
    );
};

export default ReportList;