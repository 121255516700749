import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, PageSizing } from "component/app/items";
import { Tap } from "component/basic/tap";
import { FooterManu } from "component/elements/footer";
import { bottomChk } from "js/function";
import { DetailItem, DetailItemBox } from "component/detailing/item";
import { NotItems } from "component/basic/notItems";
import {WashTypeCaption} from "../../component/wash/item";

const DetailingTicket = (props) => {
    const navigate = useNavigate();

    const [page,setPage] =  useState(1);

    const detailingApi = useGet({
        url:`/detailing?page=${page}`,
        loginType:"login"
    });

    const detailingData = detailingApi?.data?.detailing_reservations;
 
    const [itemList,setItemList] =  useState([]);

    let lastPage = false;
    let settingChk = 0;

    function addPage(){
        setPage(page + 1);
        settingChk = 1;
        setTimeout(() => {
            settingChk = 0;
        }, 300);
    };

    useEffect(() => {
        if(detailingData?.data && detailingData?.data.length > 0){
            if(page == 1){
                setItemList([...detailingData?.data]);
            }else{    
                setItemList(prev => [...prev, ...detailingData?.data]);
            }
        }else{
            setItemList([]);
        }

        lastPage = detailingData?.current_page < detailingData?.last_page;

        window.removeEventListener("scroll", handleScroll);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [detailingApi]);

    function handleScroll(){
        if(bottomChk() && lastPage && settingChk == 0){
            addPage();
        }
    };

    useEffect(() => {
        setItemList([]);
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={false}
                headTitle={null}
            >
                <Tap
                    addClass=""
                    func={(id)=>{
                        navigate(`/${id}/ticket`)
                    }}
                    val="detailing"
                    data={[
                        {id:"wash",category:"카워시"},
                        {id:"detailing",category:"디테일링"},
                        // {id:"dentrepair",category:"덴트리페어"}
                    ]}
                />
            </Header>
            <ContentsSection addClass="headerContents footerContents">
                <DetailItemBox>
                    {itemList && itemList?.length > 0 ?
                        <>
                            {itemList && itemList?.map((item, i) => (
                                <DetailItem
                                    key={i}
                                    addClass={item.status !== 1 ? "isUseType" : ""}
                                    id={item.id}
                                    name={<>{item?.car_number ? (<WashTypeCaption type={item?.car_number} addClass={"gColor"}/>) : ''}{item.name}</>}
                                    date={`<span class='${item.status == 1 ? "gTextColor" : ""}'>예약일시 : ${item.date} ${item.time}</span>`}
                                    caption={item.contents}
                                    textBtn={null}
                                    textBtnFunc={()=>{}}
                                    btnText={item?.status == 1 ? "사용가능" : item?.status == 2 ? "사용완료" : item?.status == 3 ? "취소요청" : item?.status == 4 ? "취소완료" : item?.status == 10 ? "사용불가" : ""}
                                    btnFunc={()=>{navigate(`/detailing/ticket/detail/${item.id}`)}}
                                    textType={false}
                                />
                            ))}
                        </>
                        :
                        <NotItems
                            addClass="fullPage"
                            img="/assets/images/icon/not_event.svg"
                            text="이용가능한 이용권이 없습니다."
                        />
                    }
                </DetailItemBox>
            </ContentsSection>
            <FooterManu menuChk="2"/>
        </PageSizing>
    );
};

export default DetailingTicket;