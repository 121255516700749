import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { ChkBox, CouponCustomSelect } from "component/basic/formItems";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, EditerBox, GuideTextBox, ItemSection, ItemSectionTitle, PageSizing } from "component/app/items";
import { LoadingBox, Popup, RightPopup } from "component/basic/popup";
import { WashListItem, WashTypeCaption } from "component/wash/item";
import { comFormat, couponSort, getDateGap, setDateTime } from "js/function";
import { PaymentBox, PaymentItem, PaymentSuccess } from "component/payment/item";
import { NotItems } from "component/basic/notItems";
import { CardItem } from "component/mypage/card";
import {CouponItem, CouponItemBox} from "../../component/mypage/coupon";

const WashPayment = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    const type = param.type;

    const washApi = useGet({
        url:`/car/wash/product/detail?car_wash_product_id=${id}`,
        loginType:"login"
    });
    const washData = washApi?.data?.product;

    const cashApi = useGet({
        url:type == "one" ? `/cash/remain` : null,
        loginType:"login"
    });
    const cashData = cashApi?.data?.cash;

    const couponApi = useGet({
        url:type == "one" ? `/coupon?is_available=1&product_id=${id}` : null,
        loginType:"login"
    });
    const couponData = couponApi?.data?.coupons;

    const termsApi = useGet({
        url:"/term?type=pay",
    });
    const termsData = termsApi?.data?.terms;

    const cardApi = useGet({
        url:type == "month" ? `/card` : null,
        loginType:"login"
    });
    const cardData = cardApi?.data?.user_card;

    const [couponList,setCouponList] =  useState([]);
    const [coupon,setCoupon] =  useState([]);
    const [coupons,setCoupons] =  useState([]);
    const [couponModal,setCouponModal] =  useState(false);

    const [paymentType,setPaymentType] =  useState("CARD");

    const [price,setPrice] =  useState(0);
    const [totalPrice,setTotalPrice] =  useState(0);

    const [checkItems, setCheckItems] = useState([]);
    const [checkItemTitles, setCheckItemTitles] = useState([]);
    const [terms,setTerms] =  useState({});
    const [rightOpenType,setRightOpenType] =  useState(false);

    const [guideCheck,setGuideCheck] =  useState(false);

    const [popupData,setPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);

    const [btnChk,setBtnChk] =  useState(true);

    const [paymentSuccessOpen,setPaymentSuccessOpen] =  useState(false);

    const [submitChk,setSubmitChk] =  useState(true);

    const termsDetailApi = useGet({
        url: terms?.id ? `/term/detail?term_id=${terms?.id}` : null,
    });
    const termsDetailData = termsDetailApi?.data?.term;

    const paymentList = [{
        id: "CARD",
        name: `신용/체크카드`,
        icon: "/assets/images/icon/pay_cart.svg",
        disabled:false
    },{
        id: "CASH",
        name: `캐시 ${comFormat(cashData||0)}C`,
        icon: "/assets/images/icon/pay_cash.svg",
        disabled: (cashData||0) >= totalPrice ? false : true
    },{
        id: "NAVER",
        name: `네이버페이`,
        icon: "/assets/images/icon/pay_naver.svg",
        disabled:false
    },{
        id: "KAKAO",
        name: `카카오페이`,
        icon: "/assets/images/icon/pay_kakao.svg",
        disabled:false
    }]
    
    // 체크박스 선택
    const handleSingleCheck = (checked, id, text) => {
         if (checked) {
            setCheckItemTitles(prev => [...prev, text]);
            setCheckItems(prev => [...prev, id]);
        } else {
            setCheckItemTitles(checkItemTitles.filter((el) => el !== text));
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {
        const idArr = [],
            textArr = [];
            termsData?.forEach((el) => {idArr.push(el.id);textArr.push(el.term_title)});
            if (type === 'month') {
                idArr.push('month');
                textArr.push('현재 등록된 차량번호에 정기권이 귀속됨을 확인합니다')
            }
            setCheckItemTitles(textArr);
            setCheckItems(idArr);
        }
        else {
            setCheckItemTitles([]);
            setCheckItems([]);
        }
    }

    const rightOpen = (id,title) => {
        let termsItem = {
            id:id,
            title:title
        }
        setTerms(termsItem);
        setRightOpenType(true);
    }

    function payment(){
        if(submitChk){
            if (type !== 'month' && totalPrice > 0 && totalPrice <= 100) {
                setPopupData({
                    addClass:null,
                    title:"결제 실패",
                    text:"최소 결제금액(100원) 미달입니다",
                    closeType:true,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{},
                    btn0:"확인",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });
                return false
            }

            setSubmitChk(false);
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("car_wash_product_id", id);
            formData.append("type", type == "one" ? "once" : "month");
            if(type == "one"){
                formData.append("payment_method", paymentType);
            }
            if(type == "one" && coupon?.length > 0){
                coupon?.map((couponItem, couponItemKey) => {
                    formData.append(`user_coupon_id[${couponItemKey}]`, couponItem?.id);
                })
            }

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/car/wash/reservation`,
                success: (data) => {
                    let payId = data?.data?.id;
                    let status = data?.data?.status;

                    setLoadingBoxOpen(false);
                    setSubmitChk(true);

                    if(status == 20){
                        window.location.href = `${process.env.REACT_APP_PAYMENT_URL}/car/wash/payment?id=${payId}&access_token=${localStorage.getItem("token")}`;
                    }else if(status == 0){
                        //결제 완료
                        setPaymentSuccessOpen(true);
                    }else{
                        setPopupData({
                            addClass:null,
                            title:"결제 실패",
                            text:"결제가 실패하였습니다.<br/>잠시 뒤 다시 시도해 주세요.",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    function cardAdd(){
        let nowUrl = new URL(window.location.href);
        sessionStorage.setItem("cardSetUrl",nowUrl.pathname);
        navigate("/mypage/card");
    }

    useEffect(() => {
        let chkSet = [...checkItems];
        if((type === 'month' ? chkSet.length == termsData?.length + 1 : chkSet.length == termsData?.length) && (type == "month" ? cardData : true) && guideCheck){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [checkItems,cardApi,guideCheck]);

    useEffect(() => {
        if(washData){
            setPrice(washData?.price);
        }
    }, [washApi]);

    useEffect(() => {
        let paymentValue = price;
        let couponValue = 0;
        let couponSetArr = couponSort(coupon);

        couponSetArr?.map((couponItem) => {
            if(couponItem.type === "percent"){
                let couponValue_ = (Number(paymentValue) * (Number(couponItem?.value) / 100))
                couponValue += couponValue_
                paymentValue -= couponValue_
            }else if(couponItem.type === "price"){
                let couponValue_ = Number(couponItem?.value)
                couponValue += couponValue_
                paymentValue -= couponValue_
            }
        });

        setTotalPrice(paymentValue > 0 ? paymentValue : 0);
    }, [price,coupon]);

    useEffect(() => {
        if(couponData){
            setCouponList(couponData)
        }
    }, [couponApi]);

    return (
        <PageSizing addClass="headerPage">
            <div className={`fullModal ${couponModal ? '' : 'active'}`}>
                <Header
                    pageBack={true}
                    func={() => {navigate(-1)}}
                    headTitle="결제하기"
                    centerTitle={true}
                />
                <ContentsSection addClass="headerContents btnContents">
                    <ItemSection>
                        <WashListItem
                            addClass="detailItem"
                            id={washData?.id}
                            name={washData ? <><WashTypeCaption type={type == "month" ? "구독권" : "1회권"} addClass={type == "month" ? "gColor" : "bColor"}/>{washData?.name}</> : ""}
                            caption={washData?.contents||""}
                            option={washData?.product_options||[]}
                            subOptionTitle={washData?.relation_product_name||null}
                            subOption={washData?.relation_product_options||[]}
                            include_inside_full_care={washData?.include_inside_full_care}
                            inside_full_care_option_count={washData?.inside_full_care_option_count}
                            use_once={null}
                            use_month={null}
                            price={null}
                            month_price={null}
                        />
                    </ItemSection>
                    {couponList.length > 0 ?
                        <>
                            <ItemSection>
                                <ItemSectionTitle
                                    contents="쿠폰"
                                />
                                <CouponCustomSelect
                                    inputName="쿠폰"
                                    placeholder="쿠폰을 선택하세요"
                                    value={coupon?.id}
                                    func={() => {
                                        setCouponModal(true)
                                        setCoupons(coupon)
                                    }}
                                    disabled={false}
                                    valKey="id"
                                    nameKey="name"
                                    subValKey="value"
                                    typeKey="type"
                                    options={couponList}
                                />
                                {coupon?.length > 0 && (
                                    <CouponItemBox addClass="select">
                                        {coupon && coupon?.map((item, i) => (
                                            <CouponItem
                                                key={i}
                                                id={item.id}
                                                addClass=""
                                                type={item.type}
                                                value={item.value}
                                                name={item.name}
                                                status={item.status}
                                                start_date={item.start_date}
                                                end_date={item.end_date}
                                                closeButton={true}
                                                closeFunc={(e) => { setCoupon(prevItems => prevItems.filter(item => item.id !== e)); }}
                                            />
                                        ))}
                                    </CouponItemBox>
                                )}
                            </ItemSection>
                        </>
                    :""}
                    <ItemSection>
                        <ItemSectionTitle
                            contents={type == "month" ? "결제카드" : "결제수단"}
                        />
                        {type == "month" ?
                            <>
                                {cardData ?
                                    <>
                                        <CardItem
                                            addClass="min"
                                            notCard={cardData ? false : true}
                                            name={cardData?.name}
                                            code={cardData?.card_code}
                                            cardDelFunc={null}
                                            cardNumber={cardData?.card_number}
                                        />
                                        <BtnItem
                                            addClass="subType line"
                                            contents="결제카드 변경"
                                            disabled={false}
                                            func={() => {cardAdd()}}
                                        />
                                    </>
                                    :
                                    <>
                                        <NotItems
                                            addClass="min"
                                            img="/assets/images/icon/not_card.svg"
                                            text="카드를 등록하지 않았어요<br/>지금 바로 결제카드를 등록해주세요"
                                        />
                                        <BtnItem
                                            addClass="subType line"
                                            contents="결제카드 등록"
                                            disabled={false}
                                            func={() => {cardAdd()}}
                                        />
                                    </>
                                }
                            </>
                            :
                            <PaymentBox>
                                {paymentList.map((item,i)=>(
                                    <PaymentItem
                                        key={i}
                                        id={item.id}
                                        name={item.name}
                                        icon={item.icon}
                                        disabled={item.disabled}
                                        activeChk={paymentType}
                                        func={(val)=>{setPaymentType(val)}}
                                    />
                                ))}
                            </PaymentBox>
                        }
                    </ItemSection>

                    <ItemSection>
                        <ChkBox
                            addClass="allChkItem"
                            func={(e) => handleAllCheck(e.target.checked)}
                            checkedType={type === 'month' ? checkItems?.length === termsData?.length + 1 : checkItems?.length === termsData?.length}
                            // chkSubType=""
                            label="모든 약관에 동의합니다."

                        />
                        {termsData?.map((item, i) => (
                            <ChkBox
                                key={i}
                                addClass=""
                                func={(e) => handleSingleCheck(e.target.checked,item.id,item.title)}
                                checkedType={checkItems.includes(item.id) ? true : false}
                                label={`(필수) ${item.title}`}
                                chkFunc={(e) => rightOpen(item.id,item.title)}
                            />
                        ))}
                        {type == "month" && (
                            <ChkBox
                                key={termsData?.length}
                                addClass=""
                                func={(e) => handleSingleCheck(e.target.checked, 'month', '현재 등록된 차량번호에 정기권이 귀속됨을 확인합니다')}
                                checkedType={checkItems.includes('month') ? true : false}
                                label={`(필수) 현재 등록된 차량번호에 정기권이 귀속됨을 확인합니다`}
                            />
                        )}
                    </ItemSection>
                    <ItemSection>
                        <GuideTextBox
                            title="이용권 유효기간 안내사항"
                            data={[
                                "1회권 (패키지 상품 포함) - 결제일로부터 1년",
                                "정기구독권 - 결제일로부터 1개월",
                                "보너스 이용권 - 결제일로부터 60일",
                                "",
                                "앱 회원 가입 시 발행되는 무료 이용권의 유효기간 : 14일",
                                "패키지 구매 시 발행되는 보너스 이용권의 유효기간  : 60일"
                            ]}
                        />
                        <ChkBox
                            addClass=""
                            func={(e) => {setGuideCheck(e.target.checked)}}
                            checkedType={guideCheck}
                            label={`(필수) 유효기간 관련 정책을 확인하였습니다`}
                        />
                    </ItemSection>
                    <BtnBox
                        boxType="fixed"
                        addClass=""
                    >
                        <BtnItem
                            addClass=""
                            contents={type == "month" ? `매 월 ${comFormat(washData?.month_price)}원 결제하기` : `총 ${comFormat(totalPrice)}원 결제`}
                            disabled={btnChk}
                            func={() => {payment()}}
                        />
                    </BtnBox>
                </ContentsSection>
                <RightPopup
                    topTitle={terms?.title}
                    addClass={rightOpenType ? "active bottomBtn" : " bottomBtn"}
                    closePopup={() => {setTerms({});setRightOpenType(false)}}
                    openType={rightOpenType}
                    bType={true}
                >
                    <EditerBox addClass="termsText" data={termsDetailData?.contents}/>
                    <BtnBox
                        boxType="fixed"
                        addClass=""
                    >
                        <BtnItem
                            addClass=""
                            contents="확인하고 동의합니다."
                            disabled={false}
                            func={() => {
                                if(!checkItems.includes(terms?.id)){
                                    handleSingleCheck(true,terms?.id,terms?.title);
                                };
                                setTerms({});setRightOpenType(false)}}
                        />
                    </BtnBox>
                </RightPopup>
                <PaymentSuccess
                    open={paymentSuccessOpen}
                    openClose={()=>{setPaymentSuccessOpen(false)}}
                />
                <Popup
                    data={popupData}
                />
                <LoadingBox openType={loadingBoxOpen}/>
            </div>
            <div className={`fullModal ${couponModal ? 'active' : ''}`}>
                <Header
                    pageBack={false}
                    func={() => {navigate(-1)}}
                    headTitle="쿠폰 선택하기"
                    centerTitle={true}
                    pageClose={true}
                    closeFunc={()=>{
                        setCouponModal(false)
                    }}
                />
                <ContentsSection addClass="headerContents">
                    <CouponItemBox>
                        {couponList?.length > 0 ?
                            <>
                                {couponList && couponList?.map((item, i) => (
                                    <CouponItem
                                        key={i}
                                        id={item.id}
                                        addClass=""
                                        type={item.type}
                                        value={item.value}
                                        name={item.name}
                                        status={item.is_use}
                                        start_date={item.start_date}
                                        end_date={item.end_date}
                                        checkbox={true}
                                        duplication_reject={item.duplication_reject}
                                        sort={item.sort}
                                        coupons={coupons}
                                        setCoupons={setCoupons}
                                    />
                                ))}
                            </>
                            :
                            <NotItems
                                addClass="fullPage"
                                img=""
                                text={`등록된 쿠폰이 없습니다.`}
                            />
                        }
                    </CouponItemBox>
                    <BtnBox
                        boxType="fixed"
                        addClass=""
                    >
                        <BtnItem
                            addClass=""
                            contents={`${coupons?.length}개 쿠폰 적용`}
                            func={() => { setCoupon(couponSort(coupons)); setCoupons([]); setCouponModal(false) }}
                        />
                    </BtnBox>
                </ContentsSection>
            </div>
        </PageSizing>
    );
};

export default WashPayment;