import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { CustomSelect, InputItemBox } from "component/basic/formItems";
import { LoadingBox, Popup, SelPopup, ToastPopup } from "component/basic/popup";
import { Header, } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import { ContentsSection, InfoTextBox, InputSection, PageSizing, PageTitle } from "component/app/items";
import { CarInfoPopupContents, CarNumber } from "component/member/item";
import useGet from "api/useGet";
import { RecommendedPopup } from "component/board/recommended";
import { getParam } from "js/function";

const Join = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const joinType = param.type;
    const recommendedCode = sessionStorage.getItem("recommendedCode");

    const [joinPage,setJoinPage] =  useState(0);
    const [popupData,setPopupData] =  useState(null);
    const [selPopupData,setSelPopupData] =  useState(null);
    const [toastPopupData,setToastPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    
    const [btnChk,setBtnChk] =  useState(true);

    const [cetified,setCetified] =  useState("");
    const [certifyErr,setCertifyErr] =  useState(null);
    const [timerCount,setTimerCount] =  useState("3:00");
    const [cetifiedChk,setCetifiedChk] =  useState(false);
    const [cetifiedComChk,setCetifiedComChk] =  useState(false);

    const [responseSeq,setResponseSeq] =  useState("");
    
    const [id,setId] =  useState("");
    const [pw,setPw] =  useState("");
    const [pwCom,setPwCom] =  useState("");

    const [idErr,setIdErr] =  useState(null);
    const [pwErr,setPwErr] =  useState(null);
    const [pwComErr,setPwComErr] =  useState(null);

    const [name,setName] =  useState("");
    const [birthday,setBirthday] =  useState("");
    const [phone,setPhone] =  useState("");
    const [phoneType,setPhoneType] =  useState("");

    const [carNumber,setCarNumber] =  useState("");
    const [carMaker,setCarMaker] =  useState({});
    const [carName,setCarName] =  useState("");
    const [carCategory,setCarCategory] =  useState("");
    const [carOwner,setCarOwner] =  useState("");

    const [carImg,setCarImg] =  useState("");

    const [submitChk,setSubmitChk] =  useState(true);

    const makerApi = useGet({
        url:joinPage == 8 ? `/car/maker` : null,
        loginType:"sessionLogin"
    });
    const makerData = makerApi?.data?.car_makers||[];

    const carCategoryData = [
        {id:"세단"},
        {id:"SUV"},
        {id:"밴/트럭/MPV"}
    ];
    
    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("3:00");
        setCertifyErr("");
        
        if (sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }
        
        timerChk(2.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;
                    setCertifyErr("인증시간이 초과되었습니다.");
                    setCetifiedComChk(true);
                    setCetified("");

                    setBtnChk(false);
                }
            }, 1000);
        }
    }

    function joinNextFunc(page){
        setBtnChk(true);
        setPwErr(null)
        setPwComErr(null)
        setIdErr(null)
                    
        setJoinPage(page ? page : joinPage+1);
    }

    function cetifiedStert(type){
        if(submitChk){
            setSubmitChk(false);
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("name", name);
            formData.append("birth", birthday.replace(/\-/g,""));
            formData.append("phone", phone);
            formData.append("mobile_carrier", phoneType.val);
            formData.append("is_join", 1);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/certification`,
                success: (data) => {
                    setSubmitChk(true);

                    timerStart();
                    setCetified("");
                    setCetifiedChk(true);
                    setLoadingBoxOpen(false);

                    setResponseSeq(data?.data?.response_seq);

                    joinNextFunc(type == "re" ? joinPage : null);
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    function carRegister(){
        if(submitChk){
            setSubmitChk(false);
            setLoadingBoxOpen(true);
            
            let formData = new FormData();

            formData.append("is_direct", joinPage == 7 ? 0 : 1);
            formData.append("car_number", carNumber);
            formData.append("car_owner", carOwner);

            if(joinPage == 8){
                formData.append("car_maker", carMaker?.name);
                formData.append("car_name", carName);
                formData.append("car_category", carCategory);
            }

            if(recommendedCode){
                formData.append("recommended_code", recommendedCode);
            }

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType: "sessionLogin",
                url: `/car/register`,
                success: (data) => {
                    setSubmitChk(true);
                    setLoadingBoxOpen(false);

                    setToastPopupData(null)
                    setPopupData({
                        addClass:null,
                        title:"회원가입 완료",
                        text:"오토노바 회원 가입을 완료했습니다!<br/>지금 바로 오토노바를 경험해 보세요.",
                        closeType:false,
                        closeFunc:(e) => {},
                        btn0:"확인",
                        btn0Type:"",
                        btnFunc0:(e)=>{
                            localStorage.setItem("token", sessionStorage.getItem("token"));
                            localStorage.setItem("refresh_token", sessionStorage.getItem("refresh_token"));
                            
                            localStorage.setItem("carSize",carNumber);
        
                            sessionStorage.removeItem("recommendedCode");
                            
                            window.logIn();

                            navigate("/event/recommended",{state: {backData: true}});
                        },
                        btn1:null,
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    function joinNext(){
        if(joinPage == 2){
            cetifiedStert();
        }else if(joinPage == 3){
            if(submitChk){
                setSubmitChk(false);
                setLoadingBoxOpen(true);

                let formData = new FormData();
                formData.append("name", name);
                formData.append("birth", birthday.replace(/\-/g,""));
                formData.append("phone", phone);
                formData.append("mobile_carrier", phoneType.val);

                formData.append("response_seq", responseSeq);
                formData.append("certification_code", cetified);
                formData.append("is_join", 1);

                fatchSet.FatchApi({
                    type:"POST",
                    formDataItem: formData,
                    loginType: joinType == "sns" ? "sessionLogin" : null,
                    url: `/certification/check`,
                    success: (data) => {
                        setSubmitChk(true);

                        setBtnChk(false);
                        setCetifiedComChk(true);

                        setTimerCount("3:00");
                        setCertifyErr("");

                        clearInterval(sval.current);
                        sval.current = null;

                        setLoadingBoxOpen(false);
                        
                        if(joinType == "email"){
                            sessionStorage.setItem("token",data.data.token.access_token);
                            sessionStorage.setItem("refresh_token",data.data.token.refresh_token);
                        }

                        joinNextFunc(joinType == "sns" ? 7 : null);
                    },
                    err: (data) => {
                        setSubmitChk(true);
                        if(data.alert){
                            setCertifyErr(data.alert);
                        }
                        setLoadingBoxOpen(false);
                    }
                })
            }
        }else if(joinPage == 4){
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("email", id);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType: "sessionLogin",
                url: `/email/check`,
                success: (data) => {
                    setLoadingBoxOpen(false);

                    joinNextFunc();
                },
                err: (data) => {
                    if(data.alert){
                        setIdErr(data.alert);
                    }
                    setLoadingBoxOpen(false);
                }
            })
        }else if(joinPage == 6){
            if(submitChk){
                setSubmitChk(false);
                setLoadingBoxOpen(true);

                let formData = new FormData();
                formData.append("email", id);
                formData.append("password", pw);
                formData.append("password_check", pwCom);
                formData.append("recommend_id", localStorage.getItem("recommend_id"));

                fatchSet.FatchApi({
                    type:"POST",
                    formDataItem: formData,
                    loginType: "sessionLogin",
                    url: `/join`,
                    success: (data) => {
                        setLoadingBoxOpen(false);
                        setSubmitChk(true);
                        joinNextFunc();
                    },
                    err: (data) => {
                        setSubmitChk(true);
                        if(data.data || data.alert){
                            setPopupData({
                                addClass:null,
                                title:data.alert||"",
                                text:data.data||"",
                                closeType:true,
                                closeFunc:(e) => {},
                                btnFunc0:(e)=>{},
                                btn0:"확인",
                                btn0Type:"",
                                btn1:"",
                                btn1Type:"",
                                btnFunc1:()=>{}
                            });
                        }
                        setLoadingBoxOpen(false);
                    }
                })
            }
        }else if(joinPage == 7){
            if(submitChk){
                setSubmitChk(false);
                setLoadingBoxOpen(true);

                let formData = new FormData();

                formData.append("car_number", carNumber);
                formData.append("car_owner", carOwner);

                fatchSet.FatchApi({
                    type:"POST",
                    formDataItem: formData,
                    loginType: "sessionLogin",
                    url: `/car/info`,
                    success: (data) => {
                        setSubmitChk(true);
                        setLoadingBoxOpen(false);

                        let makerData = {
                            id:"",
                            name:data?.data?.maker
                        }              
                        setCarMaker(makerData);
                        setCarName(data?.data?.name);
                        setCarCategory(data?.data?.category);

                        setCarImg(data?.data?.car_img);

                        setToastPopupData({
                            addClass:null,
                            title:"차량 정보 확인",
                            closeType:true,
                            closeFuncChk:false,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{},
                            textBtn:"직접 입력",
                            textBtnFunc:()=>{
                                setBtnChk(true);
                                setPwErr(null);
                                setPwComErr(null);
                                setIdErr(null);
                    
                                setCarNumber("");
                                setCarMaker({});
                                setCarName("");
                                setCarCategory("");
                                setCarOwner("");
                                            
                                setJoinPage(8);
                            }
                        });
                    },
                    err: (data) => {
                        setSubmitChk(true);
                        if(data.alert == "일 차량조회 횟수 초과"){
                            setPopupData({
                                addClass:null,
                                title:data.alert||"",
                                text:data.data||"",
                                closeType:true,
                                closeFunc:(e) => {},
                                btnFunc0:(e)=>{},
                                btn0:"취소",
                                btn0Type:"",
                                btn1:"직접 입력",
                                btn1Type:"",
                                btnFunc1:()=>{
                                    setBtnChk(true);
                                    setPwErr(null);
                                    setPwComErr(null);
                                    setIdErr(null);

                                    setCarNumber("");
                                    setCarMaker({});
                                    setCarName("");
                                    setCarCategory("");
                                    setCarOwner("");
                                                
                                    setJoinPage(8);
                                }
                            });
                        }else if(data.data || data.alert){
                            setPopupData({
                                addClass:null,
                                title:data.alert||"",
                                text:data.data||"",
                                closeType:true,
                                closeFunc:(e) => {},
                                btnFunc0:(e)=>{},
                                btn0:"확인",
                                btn0Type:"",
                                btn1:"",
                                btn1Type:"",
                                btnFunc1:()=>{}
                            });
                        }
                        setLoadingBoxOpen(false);
                    }
                })
            }
        }else if(joinPage == 8){
            carRegister();
        }else{
            joinNextFunc();
        }
    }
    
    //뒤로가기(물리버튼)
    function joinBack(){
        setPopupData({
            addClass:null,
            title:"회원가입 취소",
            text:"회원가입을 취소하시겠습니까?",
            closeType:true,
            closeFunc:(e) => {},
            btn0:"취소",
            btn0Type:"line",
            btnFunc0:(e)=>{},
            btn1:"확인",
            btn1Type:"",
            btnFunc1:()=>{navigate(-1)}
        });
    }

    window.backEvent = () => {
        joinBack();
    }

    useEffect(() => {
        let idType = /^(?=.*[a-zA-Z0-9]).{4,12}$/;
        let pwType = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/;

        if(pw !== pwCom && pw !== "" && pwCom !== ""){
            setPwComErr("비밀번호가 일치하지 않습니다.")
        }else{
            setPwComErr(null)
        }

        if(!pwType.test(pw) && pw !== ""){
            setPwErr("영문, 숫자, 특수문자 조합 8-20자로 설정해 주세요.")
        }else{
            setPwErr(null)
        }

        if(joinPage == 4){
            if(idType.test(id) && id !== ""){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(joinPage == 5){
            if(
                idType.test(id) && id !== ""
                && pwType.test(pw) && pw !== ""
            ){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(joinPage == 6){
            if(
                idType.test(id) && id !== ""
                && pwType.test(pw) && pw !== ""
                && pw == pwCom && pwType.test(pw)
            ){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }
    }, [id,pw,pwCom]);

    useEffect(() => {
        let ragChk = /\d{2}([0]\d|[1][0-2])([0][1-9]|[1-2]\d|[3][0-1])[-]*[1-4]/g;
        let phoneChk = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

        if(joinPage == 0){
            if(name.length > 0){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(joinPage == 1){
            if(name.length > 0 
            && ragChk.test(birthday.replace(/\-/g,""))){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(joinPage == 2){
            if(name.length > 0 
            && ragChk.test(birthday.replace(/\-/g,""))
            && phoneChk.test(phone)
            && phoneType.val){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(joinPage == 3){
            if(name.length > 0 
            && ragChk.test(birthday.replace(/\-/g,""))
            && phoneChk.test(phone)
            && phoneType.val
            && cetified.length == 6){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }
    }, [name,birthday,phone,phoneType,cetified]);

    useEffect(() => {
        let carChk = /\d{2,3}[가-힣]{1}\d{4}/gm;

        if(joinPage == 7){
            if(carChk.test(carNumber) && carOwner !== ""){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(joinPage == 8){
            if(carChk.test(carNumber)
            && carMaker?.id
            && carName !== ""
            && carCategory !== ""
            && carOwner !== ""){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }
    }, [carNumber,carOwner,carMaker,carName,carCategory]);
    

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {joinBack()}}
                headTitle={""}
            />
            <ContentsSection addClass="headerContents btnContents">
                <PageTitle
                    contents={joinPage == 0 ? "이름을<br/>입력해 주세요."
                    : joinPage == 1 ? "주민등록번호 앞 6자리와<br/>뒤 1자리를 입력해 주세요."
                    : joinPage == 2 ? "휴대폰 번호를<br/>입력해 주세요."
                    : joinPage == 3 ? "인증번호를<br/>입력해 주세요."
                    : joinPage == 4 ? "아이디를<br/>입력해 주세요."
                    : joinPage == 5 ? "비밀번호를<br/>입력해 주세요."
                    : joinPage == 6 ? "비밀번호를 한 번 더<br/>입력해 주세요."
                    : joinPage == 7 || joinPage == 8 ? "고객님의 차량번호를<br/>입력해 주세요."
                    : ""}
                    subTitle={joinPage == 0 || joinPage == 1 || joinPage == 2 || joinPage == 3 ? "회원가입을 위해 휴대폰 인증이 필요해요."
                    : joinPage == 4 || joinPage == 5 || joinPage == 6 ? "오토노바에서 이용하실 계정 정보를 입력해 주세요."
                    : ""}
                    subTitleBtn={joinPage == 7 ? "직접 입력하시겠어요?" : null}
                    subTitleBtnFunc={()=>{
                        if(joinPage == 7){
                            setBtnChk(true);
                            setPwErr(null);
                            setPwComErr(null);
                            setIdErr(null);

                            setCarNumber("");
                            setCarMaker({});
                            setCarName("");
                            setCarCategory("");
                            setCarOwner("");
                                        
                            setJoinPage(8);
                        }
                    }}
                />
                <InputSection>
                    {
                        joinPage <= 3 && cetifiedChk ? 
                            <InputItemBox
                                inputType={null}
                                addClass="cetifiedInput"
                                inputName="인증 번호"
                                placeholder="인증 번호"
                                value={cetified}
                                max={6}
                                readOnly={cetifiedComChk}
                                cetified={timerCount}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setCetified(value)}}
                                errMsg={certifyErr}
                                subBtn="인증번호 재발송"
                                inputSubFunc={()=>{cetifiedStert("re");setCetifiedComChk(false);setCetified("")}}
                            />
                        :
                        ""
                    }
                    {
                        joinPage >= 2 && joinPage <= 3 ? 
                            <>
                                <InputItemBox
                                    type={null}
                                    addClass=""
                                    inputName="통신사"
                                    placeholder="통신사"
                                    value={phoneType.text}
                                    readOnly={true}
                                    func={(e) => {}}
                                    selItem={true}
                                    clickInput={(e) => setSelPopupData({
                                        title:"통신사 선택",
                                        addClass:null,
                                        closeType:true,
                                        items:["SKT","KT","LGU+","알뜰폰(SKT)","알뜰폰(KT)","알뜰폰(LGU+)"],
                                        val:[1,2,3,4,5,6],
                                        sel:phoneType.val,
                                        closeFunc:(e) => {},
                                        func:(e) => {
                                            setPhoneType({
                                                text:e.text,
                                                val:e.val
                                            });
                                            setCetifiedChk(false);setCetifiedComChk(false);setCetified("")
                                        }
                                    })}
                                />
                                <InputItemBox
                                    inputType={null}
                                    addClass=""
                                    inputName="휴대폰 번호"
                                    placeholder="‘-’ 없이 입력"
                                    value={phone}
                                    max={11}
                                    regexp={"number"}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    func={(value)=>{setPhone(value);setCetifiedChk(false);setCetifiedComChk(false);setCetified("");setJoinPage(2)}}
                                />
                            </>
                        :
                        ""
                    }
                    {
                        joinPage >= 1 && joinPage <= 3 ? 
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="주민등록번호 앞 6자리 + 뒤 1자리"
                                placeholder="주민등록번호 앞 6자리 + 뒤 1자리"
                                birthSetting={true}
                                value={birthday}
                                regexp={/[^0123456789\-]/gi}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setBirthday(value)}}
                            />
                        :
                        ""
                    }
                    {
                        joinPage >= 0 && joinPage <= 3 ? 
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="이름"
                            placeholder="이름"
                            value={name}
                            max={10}
                            regexp={null}
                            func={(value)=>{setName(value)}}
                        />
                        :
                        ""
                    }

                    {
                        joinPage >= 4 && joinPage <= 6 ? 
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="아이디"
                            placeholder="아이디"
                            value={id}
                            max={12}
                            regexp={/[^0-9a-zA-Z]/gi}
                            errMsg={idErr}
                            func={(value)=>{setId(value)}}
                            caption="영문 대/소문자, 숫자 4-12자"
                        />
                        :
                        ""
                    }
                    {
                        joinPage >= 5 && joinPage <= 6 ? 
                        <InputItemBox
                            inputType="password"
                            addClass=""
                            inputName="비밀번호"
                            placeholder="비밀번호"
                            value={pw}
                            max={20}
                            regexp={null}
                            errMsg={pwErr}
                            func={(value)=>{setPw(value)}}
                            caption="영문 + 숫자 +특수조합 8~20자"
                        />
                        :
                        ""
                    }
                    {
                        joinPage == 6 ? 
                        <InputItemBox
                            inputType="password"
                            addClass=""
                            inputName="비밀번호 확인"
                            placeholder="비밀번호 확인"
                            value={pwCom}
                            max={20}
                            regexp={null}
                            errMsg={pwComErr}
                            func={(value)=>{setPwCom(value)}}
                        />
                        :
                        ""
                    }
                    {
                        joinPage == 7 ? 
                        // <CarNumber
                        //     addClass=""
                        //     placeholder="123가1234"
                        //     value={carNumber}
                        //     max={8}
                        //     regexp={null}
                        //     func={(value)=>{setCarNumber(value)}}
                        // />
                        <>
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="차량번호"
                                placeholder="차량번호"
                                value={carNumber}
                                max={8}
                                regexp={null}
                                func={(value)=>{setCarNumber(value)}}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="차량 소유주"
                                placeholder="차량 소유주"
                                value={carOwner}
                                max={null}
                                regexp={null}
                                func={(value)=>{setCarOwner(value)}}
                            />
                            <InfoTextBox
                                title="차량정보 입력 안내"
                                text='&#183; 개인 소유 차량은 실제 소유주 성함을 입력해주세요.<br/>
                                &#183; 법인/리스/렌탈 차량은 회사명을 입력해주세요.<br/>
                                <span class="infoTextLineChk"></span> 입력 시 <b>(주), 주식회사</b> 등을 포함해 정확히 입력해주세요.<br/>
                                &#183; 개인 소유 차량 소유주 입력 예시 : "<b>홍길동</b>"<br/>
                                &#183; 법인/리스/렌탈 차량 소유주 입력 예시 : "<b>메리츠캐피탈(주)</b>"'
                            />
                        </>
                        :
                        ""
                    }
                    {
                        joinPage == 8 ? 
                        <>
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="차량번호"
                                placeholder="차량번호"
                                value={carNumber}
                                max={8}
                                regexp={null}
                                func={(value)=>{setCarNumber(value)}}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="차량 소유주"
                                placeholder="차량 소유주"
                                value={carOwner}
                                max={null}
                                regexp={null}
                                func={(value)=>{setCarOwner(value)}}
                            />
                            <CustomSelect
                                inputName="차량 제조사"
                                placeholder="차량 제조사"
                                value={carMaker?.id}
                                func={(name,val) => {
                                    let dataSet = {
                                        id:val,
                                        name:name
                                    }
                                    setCarMaker(dataSet);
                                }}
                                disabled={false}
                                valKey="id"
                                nameKey="name"
                                options={makerData}
                            />
                            <InputItemBox
                                inputType={null}
                                addClass=""
                                inputName="차량 모델"
                                placeholder="차량 모델"
                                value={carName}
                                max={null}
                                regexp={null}
                                func={(value)=>{setCarName(value)}}
                            />
                            <CustomSelect
                                inputName="차량 외형"
                                placeholder="차량 외형"
                                value={carCategory}
                                func={(name,val) => {
                                    setCarCategory(val);
                                }}
                                disabled={false}
                                valKey="id"
                                nameKey="id"
                                options={carCategoryData}
                            />
                            <InfoTextBox
                                title="차량정보 입력 안내"
                                text='&#183; 개인 소유 차량은 실제 소유주 성함을 입력해주세요.<br/>
                                &#183; 법인/리스/렌탈 차량은 회사명을 입력해주세요.<br/>
                                <span class="infoTextLineChk"></span> 입력 시 <b>(주), 주식회사</b> 등을 포함해 정확히 입력해주세요.<br/>
                                &#183; 개인 소유 차량 소유주 입력 예시 : "<b>홍길동</b>"<br/>
                                &#183; 법인/리스/렌탈 차량 소유주 입력 예시 : "<b>메리츠캐피탈(주)</b>"'
                            />
                        </>
                        :
                        ""
                    }
                </InputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={joinPage == 8 ? "입력 완료" : joinPage == 3 ? "인증 완료" : "다음으로"}
                        disabled={btnChk}
                        func={() => {joinNext()}}
                    />
                </BtnBox>
            </ContentsSection>
            <SelPopup
                data={selPopupData}
            />
            <ToastPopup
                data={toastPopupData}
            >
                <CarInfoPopupContents
                    carImg={carImg}
                    maker={carMaker?.name}
                    name={carName}
                    category={carCategory}
                />
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"네, 맞아요."}
                        disabled={false}
                        func={() => {carRegister()}}
                    />
                </BtnBox>
            </ToastPopup>
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default Join;