import { TextBtnItem } from "component/basic/btns";
import { useNavigate } from "react-router-dom";

function DetailItemBox(data){
    return (
        <div className={`detailItemBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function DetailItemBtn(data){

    return (
        <>
            {data.textType ? 
                <p className={`detailItemBtn ${data.addClass||""}`} dangerouslySetInnerHTML={{__html:data.text}}/>
            :
                <button type="button" className={`detailItemBtn ${data.addClass||""}`} onClick={()=>{data.func()}} dangerouslySetInnerHTML={{__html:`${data.text}<img src="/assets/images/basic/btn_icon.svg"/>`}}/>
            }
        </>
    );
}

function DetailingTextItem(data){
    return (
        <div className="detailingTextItem">
            <p className="detailingTextItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            <p className="detailingTextItem_value" dangerouslySetInnerHTML={{__html:data.val}}/>
        </div>
    );
}

function DetailItem(data){
    let navigate = useNavigate();

    return (
        <div className={`detailItem ${data.addClass||""}`}>
            <div className="detailItem_nameBox">
                <h1 className="washListItem_name">{data.name}</h1>
                {data.date ? <p className={`detailItem_date`} dangerouslySetInnerHTML={{__html:data.date}}/> : ""}
                <p className={`detailItem_caption ${data.bTextType ? "bTextType gTextColor" : ""}`} dangerouslySetInnerHTML={{__html:data.caption}}/>
                <>{data.requiredTime || data.service || data.notes ? 
                    <div className="detailingTextBox">
                        {data.requiredTime ? 
                            <DetailingTextItem name="소요시간" val={data.requiredTime + '분'}/>
                        :""}
                        {data.service ? 
                            <DetailingTextItem name="포함된 서비스" val={data.service}/>
                        :""}
                        {data.notes ? 
                            <DetailingTextItem name="참고사항" val={data.notes}/>
                        :""}
                    </div>
                :""}</>
                {data.textBtn ? 
                    <div className="detailItem_textBtn">
                        <TextBtnItem addClass="underLine" contents={data.textBtn} func={() => {data.textBtnFunc()}}/>
                    </div>
                :""}
            </div>
            {data.btnText ? 
                <div className="detailItem_btnBox">
                    <DetailItemBtn
                        addClass=""
                        text={data.btnText}
                        textType={data.textType}
                        func={()=>{
                            data.btnFunc()
                        }}
                    />
                </div>
            :""}
        </div>
    );
}

export {DetailItemBox,DetailItem};